import React, {useEffect, useState} from 'react';


import './files.css';
import {Collapse, Form, Input, message} from "antd";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";
import Loader from "../../../loading/Loader";
import Modal from "react-modal";
import { MdOutlineModeEdit , MdDeleteOutline} from 'react-icons/md';
import {MdAdd} from 'react-icons/md';
import {BsUpload} from "react-icons/bs";

const Files = () => {

    const token = storage.local.get("token");

    const [loading , setLoading] = useState(false)
    const [linksData , setLinksData] = useState([]);
    const getData = () =>{
        axios.get(`${ip}/api/v1/sourceFile/allTopic`,
            {headers:{'Authorization': `Bearer ${token}`}}
        )
            .then((res)=>{
                setLinksData(res?.data?.item);
            }).catch((err)=>{
            message.error("Xatolik !");
        })
    }
    useEffect(()=>{
        getData()
    },[]);

    const [topicOpenModal , setTopicOpenModal] = useState(false);
    const [topicDeleteModal , setTopicDeleteModal] = useState(false);
    const [initialValuesTopic , setInitialValuesTopic] = useState({
        name : ''
    });

    const cencelTopic = () => {
        setTopicOpenModal(false);
        setTopicDeleteModal(false);
        setInitialValuesTopic({
            name: "",
        });
        setLoading(false);
    }
    const onFinishTopic = (values) => {
        setLoading(true);
        if (initialValuesTopic?.edit) {
            axios.put(`${ip}/api/v1/sourceFile/updateTopic/${initialValuesTopic?.id}?name=${values.name}`, {},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getData();
                    cencelTopic();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/sourceFile/createTopic?name=${values.name}`,
                {},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    console.log(res)
                    getData();
                    cencelTopic();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedTopic = (error) => {}
    const editTopic = (value) =>{
        setInitialValuesTopic({
            ...value,
            edit : true
        });
        setTopicOpenModal(true);
    }
    const deleteTopicModal = (value) => {
        setInitialValuesTopic({...value});
        setTopicDeleteModal(true);
    }
    const deleteTopic = () => {
        setLoading(true);

        axios.delete(`${ip}/api/v1/sourceFile/deleteTopic/${initialValuesTopic.id}` ,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                console.log(res)
                getData();
                cencelTopic();
                message.info("O'chirildi")
            }).catch((err) => {
            console.log(err)
            message.error("Xatolik !");
            setLoading(false);
        })
    }


    // add link



    const [linkOpenModal , setLinkOpenModal] = useState(false);
    const [linkDeleteModal , setLinkDeleteModal] = useState(false);
    const [initialValuesLink , setInitialValuesLink] = useState({
        id : '',
        fileId : '',
        description : '',
    });

    const addLink = (value) =>{
        setInitialValuesTopic({...value})
        setLinkOpenModal(true);
    }

    const [fileName , setFileName] = useState('')
    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });

    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setFileName(e.target.files[0].name)
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelLink = () => {
        setLinkOpenModal(false);
        setLinkDeleteModal(false);
        setInitialValuesLink({
            id : '',
            fileId : '',
            description : '',
        });
        setInitialValuesTopic({
            name: "",
        });
        setImg({});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishLink = (values) => {
        setLoading(true);

        const formData = {
            file : img ? img.image : '',
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesLink?.edit) {
            axios.put(`${ip}/api/v1/sourceFile/edit-file/${initialValuesTopic.id}/${initialValuesLink.id}/${values.description}`,
                fd,
                {
                    // data : {file : ''},
                    headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getData();
                    cencelLink();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Fayl yuklang ! O'zgartirishni xohlamasangiz Bekor qilish ni bosing !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/sourceFile/add-attachment/${initialValuesTopic.id}/${values.description}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getData();
                    cencelLink();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedLink = (error) => {}
    const editLink = (value , item) =>{
        setInitialValuesTopic({...item});
        setInitialValuesLink({
            ...value,
            edit : true
        });
        setLinkOpenModal(true);
    }
    const deleteLinkModal = (value , item) => {
        setInitialValuesTopic({...item});
        setInitialValuesLink({...value});
        setLinkDeleteModal(true);
    }
    const deleteLink = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/sourceFile/delete-file/delete/${initialValuesTopic.id}/${initialValuesLink.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                getData();
                cencelLink();
                message.info("O'chirildi");
            }).catch((err) => {
            // console.log(err);
            message.error("Xatolik !");
            setLoading(false);
        })
    }

    // add link



    return (
        <div className="links">
            {
                loading && <Loader/>
            }
            <div className="links_header">
                <h2>FOYDALI FAYLLAR</h2>
                <button onClick={() => setTopicOpenModal(true)}>
                    <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Mavzu yaratish
                </button>
            </div>
            <div className="links_body">
                <div className="links_body_inner">
                    {
                        linksData?.map((item , index)=>{
                            if (index%2==0){
                                return(
                                    <div className="links_body_inner_collapse">
                                        <Collapse
                                            items={[{
                                                key: index,
                                                label: item?.name,
                                                children:
                                                    <ul>
                                                        {item?.sourceFiles?.map((x , y)=>{
                                                            return (
                                                                <div style={{display : "flex" , justifyContent : "space-between"}}>
                                                                    <li key={y}>
                                                                        <a href={`${ip}/api/file/download/${x?.fileId}`} target='_blank'
                                                                           style={{textDecoration : "none" , color: "white"}}>{x?.description}
                                                                        </a>
                                                                    </li>
                                                                    <div className="links_body_inner_collapse_icons">
                                                                        <div className="table_icons_edit" onClick={()=>editLink(x , item)}><MdOutlineModeEdit/></div>
                                                                        <div className="table_icons_delete" onClick={()=>deleteLinkModal(x , item)}><MdDeleteOutline/></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                            },
                                            ]}
                                        />
                                        <div className="links_body_inner_collapse_icons">
                                            <div className="table_icons_add" onClick={()=>addLink(item)}><MdAdd/></div>
                                            <div className="table_icons_edit" onClick={()=>editTopic(item)}><MdOutlineModeEdit/></div>
                                            <div className="table_icons_delete" onClick={()=>deleteTopicModal(item)}><MdDeleteOutline/></div>
                                        </div>
                                    </div>
                                )
                            }

                        })
                    }
                </div>
                <div className="links_body_inner">
                    {
                        linksData?.map((item , index)=>{
                            if (index%2==1){
                                return(
                                    <div className="links_body_inner_collapse">
                                        <Collapse
                                            items={[{
                                                key: index,
                                                label: item?.name,
                                                children:
                                                    <ul>
                                                        {item?.sourceFiles?.map((x , y)=>{
                                                            return (
                                                                <div style={{display : "flex" , justifyContent : "space-between"}}>
                                                                    <li key={y}>
                                                                        <a href={`${ip}/api/file/download/${x?.fileId}`} target='_blank'
                                                                           style={{textDecoration : "none" , color: "white"}}>{x?.description}
                                                                        </a>
                                                                    </li>
                                                                    <div className="links_body_inner_collapse_icons">
                                                                        <div className="table_icons_edit" onClick={()=>editLink(x , item)}><MdOutlineModeEdit/></div>
                                                                        <div className="table_icons_delete" onClick={()=>deleteLinkModal(x , item)}><MdDeleteOutline/></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                            },
                                            ]}
                                        />
                                        <div className="links_body_inner_collapse_icons">
                                            <div className="table_icons_add" onClick={()=>addLink(item)}><MdAdd/></div>
                                            <div className="table_icons_edit" onClick={()=>editTopic(item)}><MdOutlineModeEdit/></div>
                                            <div className="table_icons_delete" onClick={()=>deleteTopicModal(item)}><MdDeleteOutline/></div>
                                        </div>
                                    </div>
                                )
                            }

                        })
                    }
                </div>
            </div>

            <Modal
                isOpen={topicOpenModal}
                onRequestClose={cencelTopic}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="library_body_modal_inner">
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={initialValuesTopic}
                        requiredMark='optional'
                        onFinish={onFinishTopic}
                        onFinishFailed={onFinishFailedTopic}
                        autoComplete="off"
                    >
                        <div className="library_body_modal_inner_addModal_inner_text">
                            {initialValuesTopic?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Mavzu qo'shish</h2>}
                        </div>
                        <Form.Item
                            label={"Nomi"}
                            name="name"
                            rules={[{
                                required: true, message: 'Nomini kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <div className="library_body_modal_inner_addModal_inner_buttons">
                            <button type='button' onClick={cencelTopic}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                isOpen={topicDeleteModal}
                // onRequestClose={cencelVideo}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Rostdan o'chirmoqchimisiz ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={cencelTopic}>Yo'q</button>
                        <button onClick={deleteTopic}>Ha</button>
                    </div>
                </div>
            </Modal>

            {/*link*/}
            <Modal
                isOpen={linkOpenModal}
                onRequestClose={cencelLink}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="library_body_modal_inner">
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={initialValuesLink}
                        requiredMark='optional'
                        onFinish={onFinishLink}
                        onFinishFailed={onFinishFailedLink}
                        autoComplete="off"
                    >
                        <div className="library_body_modal_inner_addModal_inner_text">
                            {initialValuesLink?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Fayl qo'shish</h2>}
                        </div>
                        <Form.Item
                            label={"Tavsifi (Nomi)"}
                            name="description"
                            rules={[{
                                required: true, message: 'Tavsifi kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <div className="user_list_modal_uploadImg_right">
                            <div className="user_list_modal_uploadImg_right_inner">
                                <label htmlFor='add_staff_img' className="upload_button">
                                    <div className="upload_button_icon">
                                        <BsUpload size={22} style={{marginRight: "8px"}}/>
                                        {"Fayl yuklash"}
                                    </div>
                                    <input onChange={upload} name='image' type="file"
                                           id="add_staff_img"
                                           style={{display: 'none'}}
                                    />
                                </label>
                                <div className="upload_button_text">
                                    {/*{imageState.uploaded ?*/}
                                    {/*    <span>{fileName}</span>*/}
                                    {/*    : ""*/}
                                    {/*}*/}
                                    {
                                        initialValuesLink.fileId && imageState.initial ?
                                            <span>Biriktirilgan fayl IDsi : {initialValuesLink?.fileId}</span>
                                            : (imageState.uploaded ? <span>{fileName}</span> : "")
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="library_body_modal_inner_addModal_inner_buttons">
                            <button type='button' onClick={cencelLink}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                isOpen={linkDeleteModal}
                // onRequestClose={cencelVideo}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Rostdan o'chirmoqchimisiz ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={cencelLink}>Yo'q</button>
                        <button onClick={deleteLink}>Ha</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Files;