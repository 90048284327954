import React from 'react';
import {Table , Image} from 'antd';

import '../landingPageAdds.css';
import styled from 'styled-components';
import youtubeIcon from '../../../../../images/user/userDashboard/Vector.png';
import videoplayIcon from '../../../../../images/user/userDashboard/videoplayIcon.png';
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDeleteOutline, MdOndemandVideo, MdOutlineModeEdit} from "react-icons/md";
import ReactPlayer from "react-player";

export const TableStyles = styled(Table)`
  tbody {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.trhover};
  }
`;
const VideoTable = (props) => {
    const {
        videoData,
        setVideoOpenModal,
        setInitialValuesVideo,
        setVideoOpenDeleteModal,
        rowSelection
    } = props;

    const edit = (value, url) => {
        setInitialValuesVideo({...value, edit: true});
        setVideoOpenModal(true);
    }
    const deleteUrl = (value, record) => {
        setInitialValuesVideo({...value});
        setVideoOpenDeleteModal(true);
    }

    const columnsUz = [
        {
            title: 'T/r',
            dataIndex: 'key',
            // align: 'center'
        },
        {
            title: "Videolar",
            dataIndex: '',
            // align: 'center',
            render: (text, record) => (
                <div className="landing_adds_body_inner_inner_table_video">
                    <Image
                        width={40}
                        height={40}
                        preview={{
                            destroyOnClose: true,
                            imageRender: () => (
                                <ReactPlayer url={`${record?.videoUrl}`}
                                             controls
                                             width={"50%"}
                                             height={"55%"}
                                             playing={true}
                                             loop={true}
                                             config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                                />
                            ),
                            toolbarRender: () => null,
                            mask: (<MdOndemandVideo style={{fontSize : "22px"}}/>),
                            maskClassName: 'customize-mask',
                        }}
                        src={videoplayIcon}
                    />
                </div>
            )
        },
        {
            title: "Video url",
            dataIndex: 'videoUrl',
            // align: 'center',
            render: (text, record) => (
                <div>
                    {record?.videoUrl}
                </div>
            )
        },
        {
            title: "Amallar",
            dataIndex: '',
            // align: 'center',
            render: (text, record) => (
                <div className="table_icons">
                    <div className="table_icons_edit" onClick={() => edit(text, record)}><MdOutlineModeEdit/></div>
                    <div className="table_icons_delete" onClick={()=>deleteUrl(text, record)}><MdDeleteOutline/></div>
                </div>
            )
        }
    ]
    return (
        <TableStyles
            rowSelection={rowSelection}
            columns={columnsUz}
            dataSource={videoData}
            pagination={false}
        />
    );
};

export default VideoTable;