import React from 'react';
import {Pagination} from "antd";

import styled from "styled-components";
export const PaginationStyles = styled(Pagination)`
  .ant-pagination-item, .ant-pagination-item-link, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  
`;

const LibraryPagination = (props) => {

    const {
        libraryTotal,
        libraryPaginationCurrent,
        libraryPaginationLimit,
        libraryPaginationOnchange
    }=props

    return<PaginationStyles
        dropdownRender = {false}
        defaultPageSize={libraryPaginationLimit}
        current={libraryPaginationCurrent}
        onChange={libraryPaginationOnchange}
        showSizeChanger={false}
        total={libraryTotal}
        pageSize={libraryPaginationLimit}
        pageSizeOptions={[7, 14, 21, 28]}
        locale={{ items_per_page: 'sahifa'}}
    />;
};

export default LibraryPagination;