import { createStore, combineReducers } from "redux";
import {Reducer} from "./reducer";



const allReducers = combineReducers({
    auth: Reducer,
});

export const store = createStore(allReducers);
