import React, {useEffect, useState} from 'react';


import './users.css';
import searchIcon from '../../../../images/user/userDashboard/searchIcon.png';
import UsersTable from "./UsersTable";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";
import Modal from "react-modal";
import {Form, Input, message, Radio, Space} from "antd";
import Loader from "../../../loading/Loader";
import {MdAdd} from "react-icons/md";
import VideoTable from "../landingPageAdds/table/VideoTable";
import {BsUpload} from "react-icons/bs";
import {HiEllipsisHorizontal} from "react-icons/hi2";

const {TextArea} = Input;
const Users = () => {

        const token = storage.local.get("token");

        const [loading, setLoading] = useState(false);

        const [initialValuesLibrary, setInitialValuesLibrary] = useState({
            fullName: "",
            phoneNumber: "",
            username: "",
            id: "",
            premium: "",
            premiumBefore: "",
        });
        const [libraryOpenModal, setLibraryOpenModal] = useState(false);
        const [libraryOpenDeleteModal, setLibraryOpenDeleteModal] = useState(false);
        const [libraryData, setLibraryData] = useState([]);
        const [libraryDataTrue, setLibraryDataTrue] = useState([]);
        const [libraryDataFalse, setLibraryDataFalse] = useState([]);
        const [libraryDataAll, setLibraryDataAll] = useState([]);

        const getAllUsers = () => {
            axios.get(`${ip}/api/v1/users/all-user`,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    const newData = res?.data?.item?.map((item, index) => (
                        {
                            ...item,
                            key: index + 1,
                            fullName: item.fullName,
                            id: item.id,
                            phoneNumber: item.phoneNumber,
                            premium: item.premium,
                            premiumBefore: item.premiumBefore,
                            username: item.username,
                        }
                    ))
                    setLibraryData(newData);
                })
                .catch((err) => {

                })
        }

        useEffect(() => {
            const premiumTrue = libraryData.filter((item) => item.premium).map((item) => ({
                email: item.username,
                userId: item.id
            }));
            const premiumFalse = libraryData.filter((item) => !item.premium).map((item) => ({
                email: item.username,
                userId: item.id
            }));
            const premiumAll = libraryData.filter((item) => item).map((item) => ({
                email: item.username,
                userId: item.id
            }));

            setLibraryDataTrue(premiumTrue);
            setLibraryDataFalse(premiumFalse);
            setLibraryDataAll(premiumAll);
        }, [libraryData]);

        useEffect(() => {
            getAllUsers();
        }, []);

        const cencelLibrary = () => {
            setLibraryOpenModal(false);
            setInitialValuesLibrary({
                fullName: "",
                phoneNumber: "",
                username: "",
                id: "",
                premium: "",
                premiumBefore: "",
            });
            setLoading(false);
        }
        const onFinishLibrary = (values) => {
            setLoading(true);
            const formData = {
                userId: initialValuesLibrary?.id,
                newPassword: values?.newPassword,
                reEnteredPassword: values?.reEnteredPassword
            };
            const fd = new FormData();
            Object.keys(formData).forEach(i => fd.append(i, formData[i]));

            if (values?.newPassword === values?.reEnteredPassword) {
                axios.put(`${ip}/api/v1/auth/reset-password`,
                    formData
                    ,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res)
                        getAllUsers();
                        cencelLibrary();
                        message.success("Muaffaqqiyatli o'zgartirildi")
                    }).catch((err) => {
                    message.error("Xatolik !");
                    setLoading(false);
                })
            } else {
                message.error("Tasdiqlash paroli noto'g'ri");
                setLoading(false);
            }
        }
        const onFinishFailedLibrary = (error) => {
        }

        // premium
        const [openPremiumModal, setOpenPremiumModal] = useState(false);
        const [valuePremium, setValuePremium] = useState(1);
        const onChangePremium = (e) => {
            setValuePremium(e.target.value);
        };
        const cencelPremium = () => {
            setOpenPremiumModal(false);
            setLibraryOpenDeleteModal(false);
            setInitialValuesLibrary({
                fullName: "",
                phoneNumber: "",
                username: "",
                id: "",
                premium: "",
                premiumBefore: "",
            });
            // setQuery('')
            // setFilteredData(libraryData);
            setLoading(false);
        }
        const onFinishPremium = (values) => {
            setLoading(true);
            if (initialValuesLibrary?.edit) {
                axios.post(`${ip}/api/v1/subscription/add-subscriber`,
                    {
                        userId: initialValuesLibrary.id,
                        duration: values.radio
                    }
                    ,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res)
                        getAllUsers();
                        cencelPremium();
                        message.success("Muaffaqqiyatli");
                    }).catch((err) => {
                    message.error("Xatolik !");
                    setLoading(false);
                })
            } else {
                axios.put(`${ip}/api/v1/subscription/cancel-subscription`,
                    {userId: initialValuesLibrary.id,},
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res)
                        getAllUsers();
                        cencelPremium();
                        message.success("Muaffaqqiyatli")
                    }).catch((err) => {
                    // console.log(err)
                    message.error("Xatolik !");
                    setLoading(false);
                })
            }
        }
        const onFinishFailedPremium = (error) => {}
        const deletePremium = () => {
            setLoading(true);
            axios.delete(`${ip}/api/v1/delete-user?userId=${initialValuesLibrary?.id}`,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getAllUsers();
                    cencelPremium();
                    message.info("O'chirildi");
                }).catch((err) => {
                // console.log(err)
                message.error("Xatolik !");
                setLoading(false);
            })
        }
        // premium

        // send message
        const [openSendModal, setOpenSendModal] = useState(false);
        const cencelSend = () => {
            setOpenSendModal(false);
            setInitialValuesLibrary({
                fullName: "",
                phoneNumber: "",
                username: "",
                id: "",
                premium: "",
                premiumBefore: "",
            });
            setLoading(false);
        }
        const onFinishSend = (values) => {
            setLoading(true);
            axios.post(`${ip}/api/v1/email/send-message`,
                {
                    userId: initialValuesLibrary.id,
                    title: values.title,
                    body: values.body
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getAllUsers();
                    cencelSend();
                    // window.location.reload();
                    message.success("Muaffaqqiyatli yuborildi");
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
        const onFinishFailedSend = (error) => {
        }
        // send message
        // all send message
        const [openSendAllModal, setOpenSendAllModal] = useState(false);
        const cencelSendAll = () => {
            setOpenSendAllModal(false);
            setInitialValuesLibrary({
                fullName: "",
                phoneNumber: "",
                username: "",
                id: "",
                premium: "",
                premiumBefore: "",
            });
            setLoading(false);
        }
        const onFinishSendAll = (values) => {
            setLoading(true);
            axios.post(`${ip}/api/v1/email/send-to-all`,
                {
                    message: values.message,
                    emails: values.radio === 1 ? libraryDataAll : values.radio === 2 ?
                        libraryDataTrue : values.radio === 3 ? libraryDataFalse : ''
                    // emails : [{email : "mirkomilablayev777@gmail.com" , userId : 657}]
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getAllUsers();
                    cencelSendAll();
                    message.success("Muaffaqqiyatli yuborildi");
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
        const onFinishFailedSendAll = (error) => {
        }
        // all send message

        // search
        const [query, setQuery] = useState('');
        const [filteredData, setFilteredData] = useState([]);

        const filterData = (searchQuery) => {
            if (!searchQuery) {
                setFilteredData(libraryData);
                return;
            }
            const filtered = libraryData.filter((item) =>
                item?.username?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
                item?.fullName?.toLowerCase()?.includes(searchQuery.toLowerCase())
            );
            setFilteredData(filtered);
        };

        const handleInputChange = (e) => {
            setQuery(e.target.value);
            filterData(e.target.value);
        };
        useEffect(() => {
            filterData(query);
        }, [libraryData, query]);

    // search

        return (
            <div className="library">
                {
                    loading && <Loader/>
                }
                <div className="library_header">
                    <h2>USERS</h2>
                    <div className="library_header_right">
                        <h3>Jami : {libraryData?.length}</h3>
                        <h3>Obuna bo'lganlar : {libraryDataTrue?.length}</h3>
                        <h3>Obuna bo'lmaganlar : {libraryDataFalse?.length}</h3>
                        <button onClick={() => setOpenSendAllModal(true)}>Xabar yuborish</button>
                    </div>
                    {/*<button onClick={() => setLibraryOpenModal(true)}>*/}
                    {/*    <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Kitob qo'shish*/}
                    {/*</button>*/}
                </div>
                <div className="library_body">
                    <div className="library_body_table">
                        <div className="library_body_search">
                            <img src={searchIcon}/>
                            <input
                                type="text"
                                value={query}
                                onChange={handleInputChange}
                                placeholder="Qidiruv..."
                            />
                        </div>
                        <UsersTable
                            data={filteredData}
                            setLibraryOpenModal={setLibraryOpenModal}
                            setInitialValuesLibrary={setInitialValuesLibrary}
                            setLibraryOpenDeleteModal={setLibraryOpenDeleteModal}
                            setOpenPremiumModal={setOpenPremiumModal}
                            setOpenSendModal={setOpenSendModal}
                            getAllUsers={getAllUsers}
                            setLoading={setLoading}
                            token={token}
                        />
                        <div className="library_body_modal">
                            <Modal
                                isOpen={libraryOpenModal}
                                onRequestClose={cencelLibrary}
                                contentLabel="My dialog"
                                className="myModal"
                                overlayClassName="myOverlay"
                                closeTimeoutMS={0}
                            >
                                <div className="library_body_modal_inner">
                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        initialValues={initialValuesLibrary}
                                        requiredMark='optional'
                                        onFinish={onFinishLibrary}
                                        onFinishFailed={onFinishFailedLibrary}
                                        autoComplete="off"
                                    >
                                        <div className="library_body_modal_inner_addModal_inner_text"><h2>Parolni
                                            o'zgartirish</h2></div>
                                        <Form.Item
                                            label="Yangi parol"
                                            name="newPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Parolni kiriting',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={"Kiriting"}/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Parolni tasdiqlash"
                                            name="reEnteredPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Parolni tasdiqlang',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={"Kiriting"}/>
                                        </Form.Item>

                                        <div className="library_body_modal_inner_addModal_inner_buttons">
                                            <button type='button' onClick={cencelLibrary}>Bekor qilish</button>
                                            <button type="submit">Saqlash</button>
                                        </div>
                                    </Form>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={openSendModal}
                                onRequestClose={cencelSend}
                                contentLabel="My dialog"
                                className="myModal"
                                overlayClassName="myOverlay"
                                closeTimeoutMS={0}
                            >
                                <div className="library_body_modal_inner">
                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        // initialValues={initialValuesLibrary}
                                        requiredMark='optional'
                                        onFinish={onFinishSend}
                                        onFinishFailed={onFinishFailedSend}
                                        autoComplete="off"
                                    >
                                        <div className="library_body_modal_inner_addModal_inner_text"><h2>Xabar
                                            yuborish</h2></div>
                                        <p className="library_body_modal_inner_addModal_inner_text_p">Email
                                            : {initialValuesLibrary.username}</p>
                                        <Form.Item
                                            label="Sarlavha"
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Sarlavhani kiriting',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={"Kiriting"}/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Xabar"
                                            name="body"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Xabarni kiriting',
                                                },
                                            ]}
                                        >
                                            {/*<Input placeholder={"Kiriting"}/>*/}
                                            <TextArea placeholder="Kiriting"/>
                                        </Form.Item>

                                        <div className="library_body_modal_inner_addModal_inner_buttons">
                                            <button type='button' onClick={cencelSend}>Bekor qilish</button>
                                            <button type="submit">Saqlash</button>
                                        </div>
                                    </Form>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={libraryOpenDeleteModal}
                                // onRequestClose={cencelVideo}
                                contentLabel="My dialog"
                                className="myModal"
                                overlayClassName="myOverlay"
                                closeTimeoutMS={0}
                            >
                                <div className="exit_modal">
                                    <div className="exit_modal_text">
                                        <h2>Rostdan o'chirmoqchimisiz ?</h2>
                                    </div>
                                    <div className="exit_modal_buttons">
                                        <button onClick={cencelPremium}>Yo'q</button>
                                        <button onClick={deletePremium}>Ha</button>
                                    </div>
                                </div>
                            </Modal>

                            {/*premium modal*/}
                            <Modal
                                isOpen={openPremiumModal}
                                onRequestClose={cencelPremium}
                                contentLabel="My dialog"
                                className="myModal"
                                overlayClassName="myOverlay"
                                closeTimeoutMS={0}
                            >
                                <div className="exit_modal_premium">
                                    <div className="exit_modal_premium_text">
                                        {
                                            initialValuesLibrary.premium ?
                                                <h2>Obunani bekor qilish !</h2> : <h2>Obuna qilish !</h2>
                                        }
                                    </div>
                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        // initialValues={initialValuesLibrary}
                                        requiredMark='optional'
                                        onFinish={onFinishPremium}
                                        onFinishFailed={onFinishFailedPremium}
                                        autoComplete="off"
                                    >
                                        <div className="exit_modal_premium_body">
                                            {
                                                initialValuesLibrary.premium ?
                                                    '' :
                                                    <Form.Item name="radio" rules={[{
                                                        required: true, message: 'Muddatni tanlang',
                                                    },]}>
                                                        <Radio.Group>
                                                            <Radio value={1}> 1 oy </Radio>
                                                            <Radio value={3}> 3 oy </Radio>
                                                            <Radio value={6}> 6 oy </Radio>
                                                            <Radio value={12}> 12 oy </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                            }
                                        </div>

                                        <div className="exit_modal_premium_buttons">
                                            <button type="button" onClick={cencelPremium}>Chiqish</button>
                                            <button type="submit">Saqlash</button>
                                        </div>
                                    </Form>
                                </div>
                            </Modal>

                            {/*send all user*/}

                            <Modal
                                isOpen={openSendAllModal}
                                onRequestClose={cencelSendAll}
                                contentLabel="My dialog"
                                className="myModal"
                                overlayClassName="myOverlay"
                                closeTimeoutMS={0}
                            >
                                <div className="library_body_modal_inner">
                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        // initialValues={initialValuesLibrary}
                                        requiredMark='optional'
                                        onFinish={onFinishSendAll}
                                        onFinishFailed={onFinishFailedSendAll}
                                        autoComplete="off"
                                    >
                                        <div className="library_body_modal_inner_addModal_inner_text"><h2>Xabar yuborish</h2></div>
                                        <Form.Item name="radio" rules={[{
                                            required: true, message: 'Tanlang',
                                        },]}>
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    <Radio value={1}> Barchaga</Radio>
                                                    <Radio value={2}> Obuna bo'lganlarga</Radio>
                                                    <Radio value={3}> Obuna bo'lmaganlarga</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            label="Xabar matni"
                                            name="message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Xabar matnini kiriting',
                                                },
                                            ]}
                                        >
                                            {/*<Input placeholder={"Kiriting"}/>*/}
                                            <TextArea placeholder="Kiriting"/>
                                        </Form.Item>
                                        <div className="library_body_modal_inner_addModal_inner_buttons">
                                            <button type='button' onClick={cencelSendAll}>Bekor qilish</button>
                                            <button type="submit">Saqlash</button>
                                        </div>
                                    </Form>
                                </div>
                            </Modal>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Users;