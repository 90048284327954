
import React, {useEffect, useState} from 'react';

import './AuthorshipAddLesson.css';
import {Collapse, Form, Input, message} from "antd";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";
import Loader from "../../../loading/Loader";
import Modal from "react-modal";
import {MdOutlineModeEdit, MdDeleteOutline, MdVideoSettings} from 'react-icons/md';
import {MdAdd} from 'react-icons/md';
import {BsUpload} from "react-icons/bs";
import {FaChevronLeft} from "react-icons/fa";
import {RiVideoAddLine} from "react-icons/ri";

const AuthorshipAddLesson = (props) => {
    const {
        initialValuescertificate,
        setInitialValuescertificate,
        setOpenLessonPage
    } = props;
    const exitLessonPage = () => {
        setInitialValuescertificate({
            name: '',
            description: '',
            price : '',
            mentorId : ''
        });
        setOpenLessonPage(false);
    }

    const token = storage.local.get("token");
    const [loading , setLoading] = useState(false)
    const [linksData , setLinksData] = useState([]);
    const getData = () => {
        axios.get(`${ip}/api/v1/course/getCourse?courseId=${initialValuescertificate?.id}&isAuthorshipCourse=${initialValuescertificate?.isAuthorshipCourse}`,
            {headers : {'Authorization': `Bearer ${token}`}}
        )
            .then((res)=>{
                setLinksData(res?.data?.item);
            }).catch((err)=>{
            message.error("Xatolik !");
        })
    }
    useEffect(() => {
        getData();
    },[]);

    const [topicOpenModal , setTopicOpenModal] = useState(false);
    const [topicDeleteModal , setTopicDeleteModal] = useState(false);
    const [initialValuesTopic , setInitialValuesTopic] = useState({
        name : ''
    });

    const cencelTopic = () => {
        setTopicOpenModal(false);
        setTopicDeleteModal(false);
        setInitialValuesTopic({
            name: "",
        });
        setLoading(false);
    }
    const onFinishTopic = (values) => {
        setLoading(true);
        if (initialValuesTopic?.edit) {
            axios.put(`${ip}/api/v1/authorship/topic/update`,
                {name : values?.name, topicId : initialValuesTopic?.id},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getData();
                    cencelTopic();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/authorship/topic/create`,
                {name : values?.name, courseId : initialValuescertificate?.id},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getData();
                    cencelTopic();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedTopic = (error) => {}
    const editTopic = (value) =>{
        setInitialValuesTopic({
            ...value,
            edit : true
        });
        setTopicOpenModal(true);
    }
    const deleteTopicModal = (value) => {
        setInitialValuesTopic({...value});
        setTopicDeleteModal(true);
    }
    const deleteTopic = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/authorship/topic/delete?topicId=${initialValuesTopic.id}` ,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                getData();
                cencelTopic();
                // message.info("O'chirildi")
                message.info(res?.data?.message)
            }).catch((err) => {
            message.error("Xatolik !");
            setLoading(false);
        })
    }


    // add link



    const [linkOpenModal , setLinkOpenModal] = useState(false);
    const [linkDeleteModal , setLinkDeleteModal] = useState(false);
    const [initialValuesLink , setInitialValuesLink] = useState({
        id : '',
        fileId : '',
        description : '',
    });

    const addLink = (value) => {
        setInitialValuesTopic({...value})
        setLinkOpenModal(true);
    }

    const cencelLink = () => {
        setLinkOpenModal(false);
        setLinkDeleteModal(false);
        setInitialValuesLink({
            id : '',
            fileId : '',
            description : '',
        });
        setInitialValuesTopic({
            name: "",
        });
        setLoading(false);
    }
    const onFinishLink = (values) => {
        setLoading(true);
        const formData = {
            topicId : initialValuesTopic?.id,
            name : values?.name
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesLink?.edit) {
            axios.put(`${ip}/api/v1/authorship/lesson/update/${initialValuesLink.id}`,
                { topicId : initialValuesTopic?.id, name : values?.name},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getData();
                    cencelLink();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/authorship/lesson/create`,
                { topicId : initialValuesTopic?.id, name : values?.name},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getData();
                    cencelLink();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedLink = (error) => {}
    const editLink = (value , item) =>{
        setInitialValuesTopic({...item});
        setInitialValuesLink({
            ...value,
            edit : true
        });
        setLinkOpenModal(true);
    }
    const deleteLinkModal = (value , item) => {
        setInitialValuesTopic({...item});
        setInitialValuesLink({...value});
        setLinkDeleteModal(true);
    }
    const deleteLink = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/authorship/delete-lesson/${initialValuesLink.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                getData();
                cencelLink();
                message.info("O'chirildi");
            }).catch((err) => {
            // console.log(err);
            message.error("Xatolik !");
            setLoading(false);
        })
    }

    // add link


    // add video

    const [videoOpenModal , setVideoOpenModal] = useState(false);
    const [initialValuesVideo , setInitialValuesVideo] = useState({
        id : '',
        name : '',
        videoId : ''
    });
    const addVideo = (value) => {
        setInitialValuesVideo({...value});
        setVideoOpenModal(true);
    }
    const editVideo = (value) =>{
        setInitialValuesVideo({...value , edit : true})
        setVideoOpenModal(true);
    }

    const [fileName , setFileName] = useState('')
    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });

    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setFileName(e.target.files[0].name)
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelVideo = () => {
        setVideoOpenModal(false);
        setInitialValuesLink({
            id : '',
            fileId : '',
            description : '',
        });
        setInitialValuesVideo({
            id : '',
            name : '',
            videoId : ''
        });
        setImg({});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishVideo = (values) => {
        setLoading(true);
        const formData = {
            file : img ? img.image : '',
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));
        if (initialValuesVideo?.edit) {
            axios.put(`${ip}/api/v1/authorship/lesson/addVideo/${initialValuesVideo.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getData();
                    cencelVideo();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Video yuklang ! O'zgartirishni xohlamasangiz Bekor qilish ni bosing !");
                setLoading(false);
            })
        } else {
            axios.put(`${ip}/api/v1/authorship/lesson/addVideo/${initialValuesVideo.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getData();
                    cencelVideo();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedVideo = (error) => {}
    // add video


    return (
       <div className="add_lesson">
           <div className="links">
               {
                   loading && <Loader/>
               }
               <div className="links_header">
                   <button onClick={exitLessonPage}>
                       <FaChevronLeft style={{marginRight: "5px", fontSize: "20px"}}/>
                   </button>
                   <h2>{initialValuescertificate?.courseName}</h2>
                   <button onClick={() => setTopicOpenModal(true)}>
                       <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Mavzu yaratish
                   </button>
               </div>
               <div className="links_body">
                   <div className="links_body_inner">
                       {
                           linksData?.topicDtoList?.map((item , index)=>{
                               if (index%2==0){
                                   return(
                                       <div className="links_body_inner_collapse">
                                           <Collapse
                                               items={[{
                                                   key: index,
                                                   label: item?.name,
                                                   children:
                                                       <ul>
                                                           {item?.lessonList?.map((x , y)=>{
                                                               return (
                                                                   <div style={{display : "flex" , justifyContent : "space-between"}}>
                                                                       <li key={y}>
                                                                           <a target='_blank'
                                                                              style={{textDecoration : "none" , color: "white"}}>{x?.name}
                                                                           </a>
                                                                       </li>
                                                                       <div className="links_body_inner_collapse_icons">
                                                                           {x?.videoId ?
                                                                               <div className="table_icons_add" onClick={() => editVideo(x)}><MdVideoSettings style={{color : "orange"}}/></div>
                                                                               :
                                                                               <div className="table_icons_add" onClick={() => addVideo(x)}><RiVideoAddLine /></div>
                                                                           }
                                                                           <div className="table_icons_edit" onClick={()=>editLink(x , item)}><MdOutlineModeEdit/></div>
                                                                           <div className="table_icons_delete" onClick={()=>deleteLinkModal(x , item)}><MdDeleteOutline/></div>
                                                                       </div>
                                                                   </div>
                                                               )
                                                           })}
                                                       </ul>
                                               },
                                               ]}
                                           />
                                           <div className="links_body_inner_collapse_icons">
                                               <div className="table_icons_add" onClick={()=>addLink(item)}><MdAdd/></div>
                                               <div className="table_icons_edit" onClick={()=>editTopic(item)}><MdOutlineModeEdit/></div>
                                               <div className="table_icons_delete" onClick={()=>deleteTopicModal(item)}><MdDeleteOutline/></div>
                                           </div>
                                       </div>
                                   )
                               }

                           })
                       }
                   </div>
                   <div className="links_body_inner">
                       {
                           linksData?.topicDtoList?.map((item , index)=>{
                               if (index%2==1){
                                   return(
                                       <div className="links_body_inner_collapse">
                                           <Collapse
                                               items={[{
                                                   key: index,
                                                   label: item?.name,
                                                   children:
                                                       <ul>
                                                           {item?.lessonList?.map((x , y)=>{
                                                               return (
                                                                   <div style={{display : "flex" , justifyContent : "space-between"}}>
                                                                       <li key={y}>
                                                                           <a target='_blank'
                                                                              style={{textDecoration : "none" , color: "white"}}>{x?.name}
                                                                           </a>
                                                                       </li>
                                                                       <div className="links_body_inner_collapse_icons">
                                                                           {x?.videoId ?
                                                                               <div className="table_icons_add" onClick={() => editVideo(x)}><MdVideoSettings style={{color : "orange"}}/></div>
                                                                               :
                                                                               <div className="table_icons_add" onClick={() => addVideo(x)}><RiVideoAddLine /></div>
                                                                           }
                                                                           <div className="table_icons_edit" onClick={()=>editLink(x , item)}><MdOutlineModeEdit/></div>
                                                                           <div className="table_icons_delete" onClick={()=>deleteLinkModal(x , item)}><MdDeleteOutline/></div>
                                                                       </div>
                                                                   </div>
                                                               )
                                                           })}
                                                       </ul>
                                               },
                                               ]}
                                           />
                                           <div className="links_body_inner_collapse_icons">
                                               <div className="table_icons_add" onClick={()=>addLink(item)}><MdAdd/></div>
                                               <div className="table_icons_edit" onClick={()=>editTopic(item)}><MdOutlineModeEdit/></div>
                                               <div className="table_icons_delete" onClick={()=>deleteTopicModal(item)}><MdDeleteOutline/></div>
                                           </div>
                                       </div>
                                   )
                               }

                           })
                       }
                   </div>
               </div>

               <Modal
                   isOpen={topicOpenModal}
                   onRequestClose={cencelTopic}
                   contentLabel="My dialog"
                   className="myModal"
                   overlayClassName="myOverlay"
                   closeTimeoutMS={0}
               >
                   <div className="library_body_modal_inner">
                       <Form
                           name="basic"
                           layout="vertical"
                           initialValues={initialValuesTopic}
                           requiredMark='optional'
                           onFinish={onFinishTopic}
                           onFinishFailed={onFinishFailedTopic}
                           autoComplete="off"
                       >
                           <div className="library_body_modal_inner_addModal_inner_text">
                               {initialValuesTopic?.edit ? <h2>O'zgartirish</h2> :
                                   <h2>Mavzu qo'shish</h2>}
                           </div>
                           <Form.Item
                               label={"Nomi"}
                               name="name"
                               rules={[{
                                   required: true, message: 'Nomini kiriting',
                               },]}
                           >
                               <Input
                                   size="large"
                                   placeholder={"Kiriting"}
                               />
                           </Form.Item>
                           <div className="library_body_modal_inner_addModal_inner_buttons">
                               <button type='button' onClick={cencelTopic}>Bekor qilish</button>
                               <button type="submit">Saqlash</button>
                           </div>
                       </Form>
                   </div>
               </Modal>
               <Modal
                   isOpen={topicDeleteModal}
                   // onRequestClose={cencelVideo}
                   contentLabel="My dialog"
                   className="myModal"
                   overlayClassName="myOverlay"
                   closeTimeoutMS={0}
               >
                   <div className="exit_modal">
                       <div className="exit_modal_text">
                           <h2>Rostdan o'chirmoqchimisiz ?</h2>
                       </div>
                       <div className="exit_modal_buttons">
                           <button onClick={cencelTopic}>Yo'q</button>
                           <button onClick={deleteTopic}>Ha</button>
                       </div>
                   </div>
               </Modal>

               {/*link*/}
               <Modal
                   isOpen={linkOpenModal}
                   onRequestClose={cencelLink}
                   contentLabel="My dialog"
                   className="myModal"
                   overlayClassName="myOverlay"
                   closeTimeoutMS={0}
               >
                   <div className="library_body_modal_inner">
                       <Form
                           name="basic"
                           layout="vertical"
                           initialValues={initialValuesLink}
                           requiredMark='optional'
                           onFinish={onFinishLink}
                           onFinishFailed={onFinishFailedLink}
                           autoComplete="off"
                       >
                           <div className="library_body_modal_inner_addModal_inner_text">
                               {initialValuesLink?.edit ? <h2>O'zgartirish</h2> :
                                   <h2>Video nomini qo'shish</h2>}
                           </div>
                           <Form.Item
                               label={"Video (Nomi)"}
                               name="name"
                               rules={[{
                                   required: true, message: 'Video (Nomi)ni kiriting',
                               },]}
                           >
                               <Input
                                   size="large"
                                   placeholder={"Kiriting"}
                               />
                           </Form.Item>
                           <div className="library_body_modal_inner_addModal_inner_buttons">
                               <button type='button' onClick={cencelLink}>Bekor qilish</button>
                               <button type="submit">Saqlash</button>
                           </div>
                       </Form>
                   </div>
               </Modal>
               {/*lesson add video*/}
               <Modal
                   isOpen={videoOpenModal}
                   onRequestClose={cencelVideo}
                   contentLabel="My dialog"
                   className="myModal"
                   overlayClassName="myOverlay"
                   closeTimeoutMS={0}
               >
                   <div className="library_body_modal_inner">
                       <Form
                           name="basic"
                           layout="vertical"
                           initialValues={initialValuesLink}
                           requiredMark='optional'
                           onFinish={onFinishVideo}
                           onFinishFailed={onFinishFailedVideo}
                           autoComplete="off"
                       >
                           <div className="library_body_modal_inner_addModal_inner_text">
                               {initialValuesVideo?.edit ? <h2>Videoni o'zgartirish</h2> :
                                   <h2>Video biriktirish</h2>}
                           </div>
                           <div className="user_list_modal_uploadImg_right">
                               <div className="user_list_modal_uploadImg_right_inner">
                                   <label htmlFor='add_staff_img' className="upload_button">
                                       <div className="upload_button_icon">
                                           <BsUpload size={22} style={{marginRight: "8px"}}/>
                                           {"Video yuklash"}
                                       </div>
                                       <input onChange={upload} name='image' type="file"
                                              id="add_staff_img"
                                              style={{display: 'none'}}
                                       />
                                   </label>
                                   <div className="upload_button_text">
                                       {
                                           initialValuesVideo.videoId && imageState.initial ?
                                               <span>Biriktirilgan video IDsi : {initialValuesVideo?.videoId}</span>
                                               : (imageState.uploaded ? <span>{fileName}</span> : "")
                                       }
                                   </div>
                               </div>
                           </div>
                           <div className="library_body_modal_inner_addModal_inner_buttons">
                               <button type='button' onClick={cencelVideo}>Bekor qilish</button>
                               <button type="submit">Saqlash</button>
                           </div>
                       </Form>
                   </div>
               </Modal>
               {/*lesson add video*/}
               <Modal
                   isOpen={linkDeleteModal}
                   // onRequestClose={cencelVideo}
                   contentLabel="My dialog"
                   className="myModal"
                   overlayClassName="myOverlay"
                   closeTimeoutMS={0}
               >
                   <div className="exit_modal">
                       <div className="exit_modal_text">
                           <h2>Rostdan o'chirmoqchimisiz ?</h2>
                       </div>
                       <div className="exit_modal_buttons">
                           <button onClick={cencelLink}>Yo'q</button>
                           <button onClick={deleteLink}>Ha</button>
                       </div>
                   </div>
               </Modal>
           </div>
       </div>
    );
};

export default AuthorshipAddLesson;

