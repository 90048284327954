import React, {useEffect, useState} from 'react';
import {Form, Input, message, Tabs} from 'antd';
import Modal from "react-modal";
import './landingPageAdds.css';
import {MdAdd} from "react-icons/md";
import VideoTable from "./table/VideoTable";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import Loader from "../../../loading/Loader";
import ImgTable from "./table/ImgTable";
import {BsUpload} from "react-icons/bs";
import modalImg from '../../../../images/gallery-add.png';
import CourseTable from "./table/CourseTable";


const {TextArea} = Input;
const {TabPane} = Tabs;
const LandingPageAdds = () => {
    const token = storage.local.get("token");
    const [loading, setLoading] = useState(false);

    const onChangeTabs = (key) => {
        // console.log(key);
    }

    const [videoData, setVideoData] = useState([]);
    const [videoOpenModal, setVideoOpenModal] = useState(false);
    const [videoOpenDeleteModal, setVideoOpenDeleteModal] = useState(false);
    const [initialValuesVideo, setInitialValuesVideo] = useState([{
        videoUrl: '', id: '', name: '', description: '', author: ""
    }]);


    const [imgData, setImgData] = useState([]);
    const [imgOpenModal, setImgOpenModal] = useState(false);
    const [imgOpenDeleteModal, setImgOpenDeleteModal] = useState(false);
    const [initialValuesImg, setInitialValuesImg] = useState([{image: '', id: ''}]);


    const [courseData, setCourseData] = useState([]);
    const [courseOpenModal, setCourseOpenModal] = useState(false);
    const [courseOpenDeleteModal, setCourseOpenDeleteModal] = useState(false);
    const [initialValuesCourse, setInitialValuesCourse] = useState([{image: '', id: ''}]);


    const getVideoData = () => {
        axios.get(`${ip}/api/corusel/video-feedback/get-all`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res.data.item.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        videoUrl: item?.videoUrl,
                        name: item?.name,
                        description: item?.description,
                        author: item?.author,
                        id: item?.id
                    }
                ))
                setVideoData(newData);
            }).catch((error) => {
        })
    }
    const getImgData = () => {
        axios.get(`${ip}/api/corusel/photo-feedback/get-all`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res.data.item.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        image: item?.photoId,
                        id: item?.id,
                    }
                ))
                setImgData(newData);
            }).catch((error) => {
        })
    }
    const getCourseData = () => {
        axios.get(`${ip}/api/corusel/recent-course/all`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res.data.item.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        image: item?.photoId,
                        id: item?.id,
                    }
                ))
                setCourseData(newData);
            }).catch((error) => {
        })
    }

    useEffect(() => {
        getVideoData();
        getImgData();
        getCourseData()
    }, []);

    const cencelVideo = () => {
        setVideoOpenModal(false);
        setVideoOpenDeleteModal(false);
        setInitialValuesVideo({videoUrl: '', id: '', name: '', description: '', author: ''});
        setLoading(false);
    }
    const onFinishVideo = (values) => {
        setLoading(true);
        if (initialValuesVideo?.edit) {
            axios.put(`${ip}/api/corusel/video-feedback/update/${initialValuesVideo?.id}`,
                {
                    videoUrl: values?.videoUrl,
                    name: values?.name,
                    description: values?.description,
                    author: values?.author,
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getVideoData();
                    cencelVideo();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/corusel/video-feedback/create`,
                {
                    videoUrl: values?.videoUrl,
                    name: values?.name,
                    description: values?.description,
                    author: values?.author,
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getVideoData();
                    cencelVideo();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedVideo = (error) => {
        // console.log(error)
    }
    const deleteVideoUrl = () => {
        setLoading(true);
        axios.delete(`${ip}/api/corusel/video-feedback/delete/${initialValuesVideo?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                getVideoData();
                cencelVideo();
                message.info("O'chirildi")
            }).catch((err) => {
            message.error("Xatolik !");
            setLoading(false);
        })
    }

    // img
    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });
    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelImg = () => {
        setImgOpenModal(false);
        setImgOpenDeleteModal(false);
        setImg({});
        setInitialValuesImg({image: '', id: ''});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishImg = (values) => {
        setLoading(true);

        const formData = {
            file: imageState.uploaded ? img.image : (initialValuesImg.edit ? initialValuesImg.image : ""),
            // file: img.image
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesImg?.edit) {
            axios.put(`${ip}/api/corusel/photo-feedback/update/${initialValuesImg?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    console.log(res)
                    getImgData();
                    cencelImg();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                if (initialValuesImg?.edit) {
                    message.error("Yangi rasmni yukmaladingiz!");
                }
            })
        } else {
            axios.post(`${ip}/api/corusel/photo-feedback/create`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getImgData();
                    cencelImg();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                console.log(err)
                setLoading(false);
                message.error("Xatolik !");
            })
        }
    }
    const onFinishFailedImg = (error) => {
        // console.log(error)
    }
    const deleteImg = () => {
        setLoading(true);
        axios.delete(`${ip}/api/corusel/photo-feedback/delete/${initialValuesImg?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                getImgData();
                cencelImg();
                message.info("O'chirildi")
            }).catch((err) => {
            message.error("Xatolik !");
            setLoading(false);
        })
    }
    // img

    // course
    const cencelCourse = () => {
        setCourseOpenModal(false);
        setCourseOpenDeleteModal(false);
        setImg({});
        setInitialValuesCourse({image: '', id: ''});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishCourse = (values) => {
        setLoading(true);
        const formData = {
            file: imageState.uploaded ? img.image : (initialValuesCourse.edit ? initialValuesCourse.image : ""),
            // file: img.image
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesCourse?.edit) {
            axios.put(`${ip}/api/corusel/recent-course/update/${initialValuesCourse?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getCourseData();
                    cencelCourse();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                if (initialValuesCourse?.edit) {
                    message.error("Yangi rasmni yukmaladingiz!");
                }
            })
        } else {
            axios.post(`${ip}/api/corusel/recent-course/create`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCourseData();
                    cencelCourse();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                message.error("Xatolik !");
            })
        }
    }
    const onFinishFailedCourse = (error) => {
        // console.log(error)
    }
    const deleteCourse = () => {
        setLoading(true);
        axios.delete(`${ip}/api/corusel/recent-course/delete/${initialValuesCourse?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                getCourseData();
                cencelCourse();
                message.info("O'chirildi")
            }).catch((err) => {
            message.error("Xatolik !");
            setLoading(false);
        })
    }
    // course

    return (
        <div className="landing_adds">
            {
                loading && <Loader/>
            }
            <div className="landing_adds_header">
                <h2>LANDING PAGE</h2>
                {/*<button><MdAdd style={{marginRight : "5px" , fontSize : "22px"}}/>Video add</button>*/}
            </div>
            <div className="landing_adds_body">
                <div className="landing_adds_body_inner">

                    <Tabs onChange={onChangeTabs} type="card" defaultActiveKey="1">
                        <TabPane tab={"Video url"} key="1">
                            <div className="landing_adds_body_inner_inner">
                                <div className="landing_adds_body_inner_inner_table">
                                    <VideoTable
                                        videoData={videoData}
                                        setVideoOpenModal={setVideoOpenModal}
                                        setInitialValuesVideo={setInitialValuesVideo}
                                        setVideoOpenDeleteModal={setVideoOpenDeleteModal}
                                    />
                                </div>
                                <div className="landing_adds_body_inner_inner_addModal">
                                    <Modal
                                        isOpen={videoOpenModal}
                                        onRequestClose={cencelVideo}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="landing_adds_body_inner_inner_addModal_inner">
                                            <Form
                                                name="basic"
                                                layout="vertical"
                                                initialValues={initialValuesVideo}
                                                requiredMark='optional'
                                                onFinish={onFinishVideo}
                                                onFinishFailed={onFinishFailedVideo}
                                                autoComplete="off"
                                            >
                                                <div className="landing_adds_body_inner_inner_addModal_inner_text">
                                                    {initialValuesVideo?.edit ? <h2>O'zgartirish</h2> :
                                                        <h2>Yangi qo'shish</h2>}
                                                </div>
                                                <Form.Item
                                                    label={"Muallif"}
                                                    name="author"
                                                    rules={[{
                                                        required: true, message: 'Muallifini kiriting',
                                                    },]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder={"Kiriting"}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={"Video nomi"}
                                                    name="name"
                                                    rules={[{
                                                        required: true, message: 'Video nomini kiriting',
                                                    },]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder={"Kiriting"}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={"Tavsifi"}
                                                    name="description"
                                                    rules={[{
                                                        required: true, message: 'Video tavsifini kiriting',
                                                    },]}
                                                >
                                                    {/*<Input*/}
                                                    {/*    size="large"*/}
                                                    {/*    placeholder={"Kiriting"}*/}
                                                    {/*/>*/}
                                                    <TextArea placeholder="Kiriting"/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={"Video url"}
                                                    name="videoUrl"
                                                    rules={[{
                                                        required: true, message: 'Video urlni kiriting',
                                                    },]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder={"Kiriting"}
                                                    />
                                                </Form.Item>
                                                <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                                                    <button type='button' onClick={cencelVideo}>Bekor qilish</button>
                                                    <button type="submit">Saqlash</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={videoOpenDeleteModal}
                                        // onRequestClose={cencelVideo}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="exit_modal">
                                            <div className="exit_modal_text">
                                                <h2>Rostdan o'chirmoqchimisiz ?</h2>
                                            </div>
                                            <div className="exit_modal_buttons">
                                                <button onClick={cencelVideo}>Yo'q</button>
                                                <button onClick={deleteVideoUrl}>Ha</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="landing_adds_body_inner_inner_footer">
                                    <button onClick={() => setVideoOpenModal(true)}>
                                        <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Video url qo'shish
                                    </button>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab={"Rasmlar"} key="2">
                            <div className="landing_adds_body_inner_inner">
                                <div className="landing_adds_body_inner_inner_table">
                                    <ImgTable
                                        imgData={imgData}
                                        setImgOpenModal={setImgOpenModal}
                                        setInitialValuesImg={setInitialValuesImg}
                                        setImgOpenDeleteModal={setImgOpenDeleteModal}
                                    />
                                </div>
                                <div className="landing_adds_body_inner_inner_addModal">
                                    <Modal
                                        isOpen={imgOpenModal}
                                        onRequestClose={cencelImg}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="landing_adds_body_inner_inner_addModal_inner">
                                            <Form
                                                name="basic"
                                                layout="vertical"
                                                // initialValues={initialValuesVideo}
                                                requiredMark='optional'
                                                onFinish={onFinishImg}
                                                onFinishFailed={onFinishFailedImg}
                                                autoComplete="off"
                                            >
                                                <div className="landing_adds_body_inner_inner_addModal_inner_text">
                                                    {initialValuesImg?.edit ? <h2>O'zgartirish</h2> :
                                                        <h2>Rasm qo'shish</h2>}
                                                </div>
                                                <div className="user_list_modal_uploadImg">
                                                    <div className="user_list_modal_uploadImg_left">
                                                        <div className="user_list_modal_uploadImg_left_inner">
                                                            {
                                                                initialValuesImg.edit && !imageState.check ?
                                                                    <img
                                                                        src={`${ip}/api/photo/show/${initialValuesImg.image}`}
                                                                        className="img1"/>
                                                                    :
                                                                    imageState.uploaded ?
                                                                        <img src={view} className="img1"/>
                                                                        : <img src={modalImg} className="img2"/>}
                                                        </div>
                                                    </div>
                                                    <div className="user_list_modal_uploadImg_right">
                                                        <div className="user_list_modal_uploadImg_right_inner">
                                                            <label htmlFor='add_staff_img' className="upload_button">
                                                                <div className="upload_button_icon">
                                                                    {/*<img src={UploadIcon} style={{marginRight: "8px"}}/>*/}
                                                                    <BsUpload size={22} style={{marginRight: "8px"}}/>
                                                                    {"Rasm yuklash"}
                                                                </div>
                                                                <input onChange={upload} name='image' type="file"
                                                                       id="add_staff_img"
                                                                       style={{display: 'none'}}
                                                                       className=""
                                                                />
                                                            </label>
                                                            <div className="upload_button_text">
                                                                <span>{"Maksimal fayl hajmi 1 Mb 500x500 o‘lchamda"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                                                    <button type='button' onClick={cencelImg}>Bekor qilish</button>
                                                    <button type="submit">Saqlash</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={imgOpenDeleteModal}
                                        // onRequestClose={cencelImg}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="exit_modal">
                                            <div className="exit_modal_text">
                                                <h2>Rostdan o'chirmoqchimisiz ?</h2>
                                            </div>
                                            <div className="exit_modal_buttons">
                                                <button onClick={cencelImg}>Yo'q</button>
                                                <button onClick={deleteImg}>Ha</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="landing_adds_body_inner_inner_footer">
                                    <button onClick={() => setImgOpenModal(true)}>
                                        <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Rasm qo'shish
                                    </button>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab={"Yaqinda qo'shiladigan kurslar"} key="3">
                            <div className="landing_adds_body_inner_inner">
                                <div className="landing_adds_body_inner_inner_table">
                                    <CourseTable
                                        courseData={courseData}
                                        setCourseOpenModal={setCourseOpenModal}
                                        setInitialValuesCourse={setInitialValuesCourse}
                                        setCourseOpenDeleteModal={setCourseOpenDeleteModal}
                                    />
                                </div>
                                <div className="landing_adds_body_inner_inner_addModal">
                                    <Modal
                                        isOpen={courseOpenModal}
                                        onRequestClose={cencelCourse}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="landing_adds_body_inner_inner_addModal_inner">
                                            <Form
                                                name="basic"
                                                layout="vertical"
                                                // initialValues={initialValuesVideo}
                                                requiredMark='optional'
                                                onFinish={onFinishCourse}
                                                onFinishFailed={onFinishFailedCourse}
                                                autoComplete="off"
                                            >
                                                <div className="landing_adds_body_inner_inner_addModal_inner_text">
                                                    {initialValuesCourse?.edit ? <h2>O'zgartirish</h2> :
                                                        <h2>Rasm qo'shish</h2>}
                                                </div>
                                                <div className="user_list_modal_uploadImg">
                                                    <div className="user_list_modal_uploadImg_left">
                                                        <div className="user_list_modal_uploadImg_left_inner">
                                                            {
                                                                initialValuesCourse.edit && !imageState.check ?
                                                                    <img
                                                                        src={`${ip}/api/photo/show/${initialValuesCourse.image}`}
                                                                        className="img1"/>
                                                                    :
                                                                    imageState.uploaded ?
                                                                        <img src={view} className="img1"/>
                                                                        : <img src={modalImg} className="img2"/>}
                                                        </div>
                                                    </div>
                                                    <div className="user_list_modal_uploadImg_right">
                                                        <div className="user_list_modal_uploadImg_right_inner">
                                                            <label htmlFor='add_staff_img' className="upload_button">
                                                                <div className="upload_button_icon">
                                                                    {/*<img src={UploadIcon} style={{marginRight: "8px"}}/>*/}
                                                                    <BsUpload size={22} style={{marginRight: "8px"}}/>
                                                                    {"Rasm yuklash"}
                                                                </div>
                                                                <input onChange={upload} name='image' type="file"
                                                                       id="add_staff_img"
                                                                       style={{display: 'none'}}
                                                                       className=""
                                                                />
                                                            </label>
                                                            <div className="upload_button_text">
                                                                <span>{"Maksimal fayl hajmi 1 Mb 500x500 o‘lchamda"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                                                    <button type='button' onClick={cencelCourse}>Bekor qilish</button>
                                                    <button type="submit">Saqlash</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={courseOpenDeleteModal}
                                        // onRequestClose={cencelImg}
                                        contentLabel="My dialog"
                                        className="myModal"
                                        overlayClassName="myOverlay"
                                        closeTimeoutMS={0}
                                    >
                                        <div className="exit_modal">
                                            <div className="exit_modal_text">
                                                <h2>Rostdan o'chirmoqchimisiz ?</h2>
                                            </div>
                                            <div className="exit_modal_buttons">
                                                <button onClick={cencelCourse}>Yo'q</button>
                                                <button onClick={deleteCourse}>Ha</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="landing_adds_body_inner_inner_footer">
                                    <button onClick={() => setCourseOpenModal(true)}>
                                        <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Rasm qo'shish
                                    </button>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>

                </div>
            </div>

        </div>
    );
};

export default LandingPageAdds;