import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import AdminDashboard from "../components/user/userPages/adminDashboard/AdminDashboard";
import {useLocation} from 'react-router-dom'
import CertificateTest from "../components/user/userPages/certificate/CertificateTest";
import NotFound from "../components/notFound/NotFound";
import Settings from "../components/user/userPages/settings/Settings";
import LandingPageAdds from "../components/user/userPages/landingPageAdds/LandingPageAdds";
import Library from "../components/user/userPages/library/Library";
import Links from "../components/user/userPages/links/Links";
import Files from "../components/user/userPages/files/Files";
import Users from "../components/user/userPages/users/Users";
import Certificate from "../components/user/userPages/certificate/Certificate";
import Cooperation from "../components/user/userPages/cooperation/Cooperation";
import AllCourses from "../components/user/userPages/allCourses/AllCourses";
import AuthorshipCourse from "../components/user/userPages/authorshipCourse/AuthorshipCourse";

const RootPage = (props) => {
    const {setPathName} = props;
    const location = useLocation();
    setPathName(location?.pathname);

    return (
        <Routes>
            <Route path='/admin/landing-page' element={<LandingPageAdds/>}/>
            <Route path='/admin/dashboard' element={<AdminDashboard/>}/>
            <Route path='/admin/users' element={<Users/>}/>
            <Route path='/admin/settings' element={<Settings/>}/>

            <Route path='/admin/all-courses' element={<AllCourses/>}/>
            <Route path='/admin/authorship-course' element={<AuthorshipCourse/>}/>
            {/*<Route path='/user/all-courses/module' element={<Module/>}/>*/}
            {/*<Route path='/user/payment-history' element={<PaymentHistory/>}/>*/}
            <Route path='/admin/links' element={<Links/>}/>
            <Route path='/admin/files' element={<Files/>}/>
            <Route path='/admin/library' element={<Library/>}/>
            <Route path='/admin/certificate' element={<Certificate/>}/>
            {/*<Route path='/user/test' element={<CertificateTest/>}/>*/}
            {/*<Route path='/user/cooperation' element={<NotFound/>}/>*/}
            <Route path='/admin/cooperation' element={<Cooperation/>}/>
            {/*<Route path='/user/settings' element={<NotFound/>}/>*/}
            {/*<Route path='/user/questions' element={<NotFound/>}/>*/}

            <Route path='*' element={<Navigate to="/admin/landing-page"/>}/>
            {/*<Route path='*' element={<NotFound/>}/>*/}
        </Routes>
    );
};

export default RootPage;