import React, {useEffect, useState} from 'react';


import './library.css';
import searchIcon from '../../../../images/user/userDashboard/searchIcon.png';
import LibraryTable from "./LibraryTable";
import LibraryPagination from "./LibraryPagination";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";
import Modal from "react-modal";
import {Form, Input, message} from "antd";
import Loader from "../../../loading/Loader";
import {MdAdd} from "react-icons/md";
import VideoTable from "../landingPageAdds/table/VideoTable";
import {BsUpload} from "react-icons/bs";
import {HiEllipsisHorizontal} from "react-icons/hi2";

const Library = () => {

    const token = storage.local.get("token");

    const [loading, setLoading] = useState(false);

    const [libraryTotal, setlibraryTotal] = useState(null);
    const [libraryPaginationLimit, setlibraryPaginationLimit] = useState(7);
    const [libraryPaginationCurrent, setlibraryPaginationCurrent] = useState(1);
    const libraryPaginationOnchange = (e = 1, option) => {
        setlibraryPaginationCurrent(e)
        setlibraryPaginationLimit(option)
    }

    const [initialValuesLibrary, setInitialValuesLibrary] = useState({
        name: "",
        author: "",
        subject: "",
        type: "",
        fileId: "",
        id: "",
        premium: ""
    });
    const [libraryOpenModal, setLibraryOpenModal] = useState(false);
    const [libraryOpenDeleteModal, setLibraryOpenDeleteModal] = useState(false);
    const [libraryData, setLibraryData] = useState([]);

    const getLibrary = () => {
        axios.get(`${ip}/api/v1/library/getAllBook`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // premium
                const newData = res?.data?.item?.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        name: item.name,
                        author: item.author,
                        subject: item.subject,
                        type: item.type,
                        fileId: item.fileId,
                        id: item.id,
                        premium: item.premium
                    }
                ))
                // console.log(res)
                setLibraryData(newData);
                // setFilteredData(res?.data?.it    em);
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getLibrary();
    }, []);



    // file upload
    const [fileOpenModal, setFileOpenModal] = useState(false);
    const [fileName , setFileName] = useState('')
    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });
    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setFileName(e.target.files[0].name)
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelFile = () => {
        setFileOpenModal(false);
        setFileName('');
        setInitialValuesLibrary({
            name: "",
            author: "",
            subject: "",
            type: "",
            fileId: "",
            id: "",
            premium: ""
        });
        setImg({});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishFile = (values) => {
        setLoading(true);
        const formData = {
            file : img.image,
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesLibrary?.id) {
            axios.put(`${ip}/api/v1/library/addAttachment/${initialValuesLibrary?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getLibrary();
                    cencelFile();
                    message.success("Muaffaqqiyatli");
                }).catch((err) => {
                // console.log(err)
                message.error("Fayl yuklang ! O'zgartirishni xohlamasangiz Bekor qilish ni bosing !");
                setLoading(false);
            })
        } else {
            message.info("ID mavjud emas !")
            setLoading(false);
        }
    }
    const onFinishFailedFile = (error) => {

    }
    // file upload


    const cencelLibrary = () => {
        setLibraryOpenModal(false);
        setLibraryOpenDeleteModal(false);
        setInitialValuesLibrary({
            name: "",
            author: "",
            subject: "",
            type: "",
            fileId: "",
            id: "",
            premium: ""
        });
        setLoading(false);
    }
    const onFinishLibrary = (values) => {
        setLoading(true);
        if (initialValuesLibrary?.edit) {
            axios.put(`${ip}/api/v1/library/editBook?bookId=${initialValuesLibrary?.id}`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getLibrary();
                    cencelLibrary();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/library/createBook`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getLibrary();
                    cencelLibrary();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedLibrary = (error) => {

    }
    const deleteLibrary = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/library/deleteBook?bookId=${initialValuesLibrary?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                getLibrary();
                cencelLibrary();
                message.info("O'chirildi")
            }).catch((err) => {
            // console.log(err)
            message.error("Xatolik !");
            setLoading(false);
        })
    }


    // search
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const filterData = (searchQuery) => {
        if (!searchQuery) {
            setFilteredData(libraryData);
            return;
        }
        const filtered = libraryData.filter((item) =>
            item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            item?.author?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            item?.subject?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    };
    const handleInputChange = (e) => {
        setQuery(e.target.value);
        filterData(e.target.value);
    };
    useEffect(() => {
        filterData(query);
    }, [libraryData, query]);

    // search

    return (
        <div className="library">
            {
                loading && <Loader/>
            }
            <div className="library_header">
                <h2>KUTUBXONA</h2>
                <button onClick={() => setLibraryOpenModal(true)}>
                    <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Kitob qo'shish
                </button>
            </div>
            <div className="library_body">
                <div className="library_body_table">
                    <div className="library_body_search">
                        <img src={searchIcon}/>
                        <input
                            type="text"
                            value={query}
                            onChange={handleInputChange}
                            placeholder="Qidiruv..."
                        />
                    </div>
                    <LibraryTable
                        data={filteredData}
                        setLibraryOpenModal={setLibraryOpenModal}
                        setInitialValuesLibrary={setInitialValuesLibrary}
                        setLibraryOpenDeleteModal={setLibraryOpenDeleteModal}
                        setFileOpenModal={setFileOpenModal}
                    />
                    <div className="library_body_modal">
                        <Modal
                            isOpen={libraryOpenModal}
                            onRequestClose={cencelLibrary}
                            contentLabel="My dialog"
                            className="myModal"
                            overlayClassName="myOverlay"
                            closeTimeoutMS={0}
                        >
                            <div className="library_body_modal_inner">
                                <Form
                                    name="basic"
                                    layout="vertical"
                                    initialValues={initialValuesLibrary}
                                    requiredMark='optional'
                                    onFinish={onFinishLibrary}
                                    onFinishFailed={onFinishFailedLibrary}
                                    autoComplete="off"
                                >
                                    <div className="library_body_modal_inner_addModal_inner_text">
                                        {initialValuesLibrary?.edit ? <h2>O'zgartirish</h2> :
                                            <h2>Kitob qo'shish</h2>}
                                    </div>
                                    <Form.Item
                                        label={"Nomi"}
                                        name="name"
                                        rules={[{
                                            required: true, message: 'Nomini kiriting',
                                        },]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={"Kiriting"}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={"Muallif"}
                                        name="author"
                                        rules={[{
                                            required: true, message: 'Muallifni kiriting',
                                        },]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={"Kiriting"}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={"Yo'nalish"}
                                        name="subject"
                                        rules={[{
                                            required: true, message: "Yo'nalishni kiriting",
                                        },]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={"Kiriting"}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={"Turi"}
                                        name="type"
                                        rules={[{
                                            required: true, message: 'Turini kiriting',
                                        },]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={"Kiriting"}
                                        />
                                    </Form.Item>
                                    <div className="library_body_modal_inner_addModal_inner_buttons">
                                        <button type='button' onClick={cencelLibrary}>Bekor qilish</button>
                                        <button type="submit">Saqlash</button>
                                    </div>
                                </Form>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={fileOpenModal}
                            onRequestClose={cencelFile}
                            contentLabel="My dialog"
                            className="myModal"
                            overlayClassName="myOverlay"
                            closeTimeoutMS={0}
                        >
                            <div className="library_body_modal_inner">
                                <Form
                                    name="basic"
                                    layout="vertical"
                                    // initialValues={initialValuesLi}
                                    requiredMark='optional'
                                    onFinish={onFinishFile}
                                    onFinishFailed={onFinishFailedFile}
                                    autoComplete="off"
                                >
                                    <div className="library_body_modal_inner_addModal_inner_text">
                                        {
                                            initialValuesLibrary.fileId ?
                                                <h2>Faylni o'zgartirish</h2> : <h2>Fayl biriktirish</h2>
                                        }
                                    </div>
                                    <div className="user_list_modal_uploadImg_right">
                                        <div className="user_list_modal_uploadImg_right_inner">
                                            <label htmlFor='add_staff_img' className="upload_button">
                                                <div className="upload_button_icon">
                                                    <BsUpload size={22} style={{marginRight: "8px"}}/>
                                                    {"Fayl yuklash"}
                                                </div>
                                                <input onChange={upload} name='image' type="file"
                                                       id="add_staff_img"
                                                       style={{display: 'none'}}
                                                />
                                            </label>
                                            <div className="upload_button_text">
                                                {/*{imageState.uploaded ?*/}
                                                {/*    <span>{fileName}</span>*/}
                                                {/*    : ""*/}
                                                {/*}*/}
                                                {
                                                    initialValuesLibrary.fileId ?
                                                        <span>Biriktirilgan fayl IDsi : {initialValuesLibrary?.fileId}</span>
                                                        : (imageState.uploaded ? <span>{fileName}</span> : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="library_body_modal_inner_addModal_inner_buttons">
                                        <button type='button' onClick={cencelFile}>Bekor qilish</button>
                                        <button type="submit">Saqlash</button>
                                    </div>
                                </Form>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={libraryOpenDeleteModal}
                            // onRequestClose={cencelVideo}
                            contentLabel="My dialog"
                            className="myModal"
                            overlayClassName="myOverlay"
                            closeTimeoutMS={0}
                        >
                            <div className="exit_modal">
                                <div className="exit_modal_text">
                                    <h2>Rostdan o'chirmoqchimisiz ?</h2>
                                </div>
                                <div className="exit_modal_buttons">
                                    <button onClick={cencelLibrary}>Yo'q</button>
                                    <button onClick={deleteLibrary}>Ha</button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    {/*<div className="library_body_pagination">*/}
                    {/*    <LibraryPagination*/}
                    {/*        libraryPaginationLimit={libraryPaginationLimit}*/}
                    {/*        libraryPaginationCurrent={libraryPaginationCurrent}*/}
                    {/*        libraryPaginationOnchange={libraryPaginationOnchange}*/}
                    {/*        libraryTotal={libraryTotal}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default Library;