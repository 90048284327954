import React from 'react';
import {message, Space, Table, Tag} from 'antd';


import fileIcon from '../../../../images/user/userDashboard/fileicon.png';
import './users.css';


import styled from 'styled-components';
import {ip} from "../../../../ip";
import {MdAdd, MdDeleteOutline, MdOutlineModeEdit} from "react-icons/md";
import {IoSend} from "react-icons/io5";
import falseIcon from './error-icon-4.png';
import trueIcon from './16104958.png';
import {RiLockPasswordLine} from "react-icons/ri";
import {SiCodementor} from "react-icons/si";
import axios from "axios";
import {storage} from "../../../../services";

export const TableStyles = styled(Table)`
  tbody {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.trhover};
  }
`;

const LibraryTable = (props) => {

    const {
        data,
        setLibraryOpenModal,
        setInitialValuesLibrary,
        setLibraryOpenDeleteModal,
        setOpenPremiumModal,
        setOpenSendModal,
        getAllUsers,
        setLoading,
        token
    } = props;
    const isMentor = (value, url) => {
        setLoading(true)
        axios.put(`${ip}/api/v1/users/makeMentor?userId=${value.id}`,{},
            {headers: {'Authorization': `Bearer ${token}`}})
            .then((res) => {
                getAllUsers();
                setLoading(false);
                message.success("Muaffaqqiyatli");
            }).catch((err) => {
            message.error("Xatolik !");
            setLoading(false);
        })
    }
    const sendMessage = (value, url) => {
        setInitialValuesLibrary({...value});
        setOpenSendModal(true);
    }
    const editPassword = (value, url) => {
        setInitialValuesLibrary({...value, edit: true});
        setLibraryOpenModal(true);
    }
    const deletePremium = (value, record) => {
        setInitialValuesLibrary({...value});
        setLibraryOpenDeleteModal(true);
    }
    const changePremiumCencel = (value, record) => {
        setInitialValuesLibrary({...record});
        setOpenPremiumModal(true);
    }
    const changePremium = (value, record) => {
        setInitialValuesLibrary({...record, edit: true});
        setOpenPremiumModal(true);
    }

    const columns = [
        {
            title: 'T/r',
            dataIndex: 'key',
            // align: 'center'
        },
        {
            title: "F.I.SH",
            dataIndex: 'fullName',
            key: 'name',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.fullName}</div>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'username',
            key: 'username',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.username}</div>,
        },
        {
            title: 'TELEFON NOMER',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.phoneNumber}</div>,
        },
        {
            title: 'PREMIUM',
            dataIndex: 'premium',
            key: 'premium',
            textAlign: 'center',
            render: (text, record) =>
                record.premium ?
                    <div className={"library_body_table_text library_body_table_text_pay"}
                         onClick={() => changePremiumCencel(text, record)}>Bekor qilish</div>
                    :
                    <div className={"library_body_table_text library_body_table_text_no_pay"}
                         onClick={() => changePremium(text, record)}>Obuna qilish</div>
        },
        {
            title: "AMALLAR",
            dataIndex: '',
            // align: 'center',
            render: (text, record) => (
                <div className="table_icons">
                        <div className="table_icons_mentor" onClick={() => isMentor(text, record)}>
                            {record?.isMentor ?
                                <SiCodementor style={{color: "#52c41a"}}/>
                                :
                                <SiCodementor style={{color: "white"}}/>
                            }
                        </div>


                    <div className="table_icons_send" onClick={() => sendMessage(text, record)}><IoSend/></div>
                    <div className="table_icons_edit" onClick={() => editPassword(text, record)}><RiLockPasswordLine/>
                    </div>
                    <div className="table_icons_delete" onClick={() => deletePremium(text, record)}><MdDeleteOutline/>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default LibraryTable;