import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Radio, Space, Flex, Progress, message} from 'antd';

import exitIcon from '../../../../images/user/userDashboard/testExit.png';

import "./certificateTest.css";
import ResultModal from "./resultModal/ResultModal";
import {selectOptions} from "@testing-library/user-event/dist/select-options";
import axios from "axios";
import {ip} from "../../../../ip";
import Loader from "../../../loading/Loader";
import {storage} from "../../../../services";
import Modal from "react-modal";
import {useLocation} from "react-router";

const CertificateTest = (props) => {
    const token = storage.local.get("token");
    const {
        testData,
        setTestData,
        setOpenTestPage
    } = props;

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openExitModal, setOpenExitModal] = useState(false);
    const [value, setValue] = useState(null);
    const [dataIndex, setDataIndex] = useState(1);
    const [radioCheck, setRadioCheck] = useState(false);

    const [postData, setPostData] = useState({
        certificationId: testData[0]?.certificateId,
        selectedOptions: []
    });
    const onChange = (e, questionId) => {
        setRadioCheck(true);
        setValue(e.target.value);
        const selectedOption = e.target.value;
        setPostData(prevState => {
            const existingOptionIndex = prevState?.selectedOptions.findIndex(
                option => option?.questionId === questionId
            );
            if (existingOptionIndex >= 0) {
                const updatedOptions = [...prevState.selectedOptions];
                updatedOptions[existingOptionIndex].selectedOption = selectedOption;
                return {...prevState, selectedOptions: updatedOptions};
            } else {
                return {
                    ...prevState,
                    selectedOptions: [
                        ...prevState.selectedOptions,
                        {questionId, selectedOption}
                    ]
                };
            }
        });
    };

    const nextFunc = (item) => {
        setValue(null);
        setRadioCheck(false);
        setDataIndex(dataIndex + 1);
    }

    const [result, setResult] = useState(null);
    const resultTest = () => {
        setLoading(true);
        axios.post(`${ip}/api/v1/certification/checkResult`,
            postData,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                setResult(res?.data);
                setLoading(false);
                setOpen(true);
            })
            .catch(err => {
                // console.log(err)
                message.error(err?.response?.data?.message);
                setLoading(false);
            });
    }


    const cancel = () => {
        setOpenExitModal(false);
        setOpenTestPage(false);
        setOpen(false);
        setValue(null);
        setDataIndex(1);
        setRadioCheck(false);
        setPostData({
            certificationId: testData[0]?.certificateId,
            selectedOptions: []
        });
    }


    const location = useLocation();
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
            window.history.pushState(null, document.title, window.location.href);
        });

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Chrome requires returnValue to be set
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [location]);


    return (
        <div className="certificate_test">
            {
                loading && <Loader/>
            }
            <div className="certificate_test_head">
                <Link to="/user/certificate">
                    <div className="certificate_test_head_exit" onClick={() => setOpenExitModal(true)}>
                        <h1><img src={exitIcon}/>Chiqish</h1>
                    </div>
                </Link>
            </div>

            <div className="certificate_test_body">
                {
                    [testData[dataIndex - 1]]?.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="certificate_test_body_test_text">
                                    {
                                        dataIndex > testData.length ?
                                            <h2>Test yakunlandi !</h2> :
                                            <h2>
                                                {dataIndex}. {item?.question}
                                            </h2>
                                    }
                                </div>
                                <div className="certificate_test_body_radio">
                                    {dataIndex > testData.length ?
                                        ""
                                        :
                                        <Radio.Group onChange={(e) => onChange(e, item.id)} value={value}>
                                            <Space direction="vertical">
                                                <Radio value='optionA'>{item?.optionA}</Radio>
                                                <Radio value='optionB'>{item?.optionB}</Radio>
                                                <Radio value='optionC'>{item?.optionC}</Radio>
                                                <Radio value='optionD'>{item?.optionD}</Radio>
                                            </Space>
                                        </Radio.Group>
                                    }
                                </div>
                                {
                                    dataIndex > testData.length ?
                                        <div className="certificate_test_body_next_button">
                                            <button onClick={resultTest}>Natijani ko'rish</button>
                                        </div>
                                        :
                                        <div className="certificate_test_body_next_button">
                                            <button
                                                disabled={!radioCheck}
                                                className={!radioCheck ? "disabled_button" : ""}
                                                onClick={() => nextFunc(item)}
                                            >Keyingisi
                                            </button>
                                        </div>
                                }
                            </div>
                        )
                    })
                }

                <div className="certificate_test_body_progress">
                    <Progress percent={((dataIndex - 1) * 100) / testData.length} showInfo={false}/>
                    <p>{dataIndex - 1} / {testData.length}</p>
                </div>
            </div>

            <ResultModal
                open={open}
                setOpen={setOpen}
                setOpenTestPage={setOpenTestPage}
                result={result}
            />

            <Modal
                isOpen={openExitModal}
                // onRequestClose={cancel}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={300}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Testni yakunlamoqchimisiz ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={()=>setOpenExitModal(false)}>Yo'q</button>
                        <button onClick={cancel}>Ha</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CertificateTest;