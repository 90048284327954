import React, {useEffect, useState} from 'react';

import './authorshipCourse.css';
import {Collapse, Form, Image, Input, message, Radio, Select} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import Loader from "../../../loading/Loader";
import Modal from "react-modal";
import {MdAdd, MdDeleteOutline, MdOutlineModeEdit, MdOutlineRemoveRedEye} from "react-icons/md";
import modalImg from "../../../../images/gallery-add.png";
import {BsUpload} from "react-icons/bs";
import {FcEditImage} from "react-icons/fc";
import {RiImageEditFill} from "react-icons/ri";
import {FaFolderOpen} from "react-icons/fa";
import AuthorshipAddLesson from "./AuthorshipAddLesson";
const { TextArea } = Input;

const AuthorshipCourse = () => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const [certificate, setCertificate] = useState([]);
    const [loading, setLoading] = useState(false);

    const [mentor , setMentor] = useState([]);

    const getMentor = () =>{
        axios.get(`${ip}/api/v1/getAllMentor`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setMentor(res?.data?.item);
            }).catch((err) => {
        })
    }

    const getCerData = () => {
        axios.get(`${ip}/api/v1/authorship/getAllAuthorCourse`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCertificate(res?.data?.item);
            }).catch((err) => {
        })
    }
    useEffect(() => {
        getCerData();
        getMentor();
    }, []);

    // add certificate
    const [certificateOpenModal, setcertificateOpenModal] = useState(false);
    const [certificateDeleteModal, setcertificateDeleteModal] = useState(false);
    const [initialValuescertificate, setInitialValuescertificate] = useState({
        name: '',
        description: '',
        price : '',
        mentorId : ''
    });

    const cencelcertificate = () => {
        setcertificateOpenModal(false);
        setcertificateDeleteModal(false);
        setInitialValuescertificate({
            name: '',
            description: '',
            price : '',
            mentorId : ''
        });
        setLoading(false);
    }
    const onFinishcertificate = (values) => {
        setLoading(true);
        if (initialValuescertificate?.edit) {
            axios.put(`${ip}/api/v1/authorship/course/update`,
                {
                    id: initialValuescertificate?.id,
                    name: values?.name,
                    description : values?.description,
                    price: values?.price,
                    mentorId : values?.mentorId
                },
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelcertificate();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/authorship/course/create`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelcertificate();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedcertificate = (error) => {
    }
    const editcertificate = (value) => {
        setInitialValuescertificate({
            ...value,
            edit: true
        });
        setcertificateOpenModal(true);
    }
    const deletecertificateModal = (value) => {
        setInitialValuescertificate({...value});
        setcertificateDeleteModal(true);
    }
    const deletecertificate = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/authorship/course/delete`,
            {
                data: {courseId: initialValuescertificate?.id},
                headers: {'Authorization': `Bearer ${token}`}
            }
        )
            .then((res) => {
                getCerData();
                cencelcertificate();
                message.info("O'chirildi")
            }).catch((err) => {
            // console.log(err)
            // message.error("Xatolik !");
            message.error(err?.response?.data?.message);
            setLoading(false);
        })
    }
    // add certificate

    // add img
    const [imgData, setImgData] = useState([]);
    const [imgOpenModal, setImgOpenModal] = useState(false);
    const [imgOpenDeleteModal, setImgOpenDeleteModal] = useState(false);
    const [initialValuesImg, setInitialValuesImg] = useState([{image: '', id: ''}]);

    const addImg = (item) => {
        setInitialValuesImg({...item});
        setImgOpenModal(true);
    }
    const editImg = (item) => {
        setInitialValuesImg({...item, edit: true});
        setImgOpenModal(true);
    }

    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });
    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelImg = () => {
        setImgOpenModal(false);
        setImgOpenDeleteModal(false);
        setImg({});
        setInitialValuesImg({image: '', id: ''});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishImg = (values) => {
        setLoading(true);
        const formData = {
            file: imageState.uploaded ? img.image : (initialValuesImg.edit ? initialValuesImg.image : ""),
            // file: img.image
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesImg?.edit) {
            axios.put(`${ip}/api/v1/authorship/course/add-cover/${initialValuesImg?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getCerData();
                    cencelImg();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                if (imageState.uploaded === false) {
                    message.error("Yangi rasmni yukmaladingiz!");
                }
            })
        } else {
            axios.put(`${ip}/api/v1/authorship/course/add-cover/${initialValuesImg?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelImg();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                message.error("Xatolik !");
            })
        }
    }
    const onFinishFailedImg = (error) => {
        // console.log(error)
    }
    // add img


    // add topic
    const [openLessonPage, setOpenLessonPage] = useState(false);
    const addLessonOpenModal = (value) => {
        setInitialValuescertificate({...value});
        setOpenLessonPage(true);
    }
    // add topic


    return (
        <div className="all_courses">
            {
                loading && <Loader/>
            }
            {
                openLessonPage ?
                    <AuthorshipAddLesson
                        initialValuescertificate={initialValuescertificate}
                        setInitialValuescertificate={setInitialValuescertificate}
                        setOpenLessonPage={setOpenLessonPage}
                    />
                    :
                    <div>
                        <div className="all_courses_header">
                            <h2>AVTORLIK KURSI</h2>
                            <button onClick={() => setcertificateOpenModal(true)}>
                                <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Kurs yaratish
                            </button>
                        </div>
                        <div className="all_courses_body">
                            {certificate?.map((item, index) => {
                                return (
                                    <div className="all_courses_body_card" key={index}>
                                        <div className="all_courses_body_card_header">
                                            <Image
                                                width={"100%"}
                                                height={"100%"}
                                                src={`${ip}/api/photo/show/${item?.coverPhotoId}`}
                                                title=""
                                                preview={{
                                                    mask: (<MdOutlineRemoveRedEye style={{fontSize: "22px"}}/>),
                                                    maskClassName: 'customize-mask',
                                                }}
                                            />
                                            {item?.coverPhotoId ?
                                                <div className="all_courses_body_card_header_icons"
                                                     onClick={() => editImg(item)}>
                                                    <div className="table_icons_edit">
                                                        {/*<FcEditImage />*/}
                                                        <RiImageEditFill/>
                                                        {/*<MdOutlineModeEdit/>*/}
                                                    </div>
                                                </div>
                                                :
                                                <div className="all_courses_body_card_header_icons"
                                                     onClick={() => addImg(item)}>
                                                    <div className="table_icons_add"><MdAdd/></div>
                                                </div>
                                            }
                                            <div className="all_courses_body_card_header_price">
                                                <p>Narxi : {item?.price}</p>
                                            </div>
                                        </div>
                                        <div className="all_courses_body_inner">
                                            <div className="all_courses_body_card_edit_delete">
                                                <div className="all_courses_body_card_edit_delete_text">
                                                    <h2>{item?.name}</h2>
                                                </div>
                                                <div className="all_courses_body_card_body_icons">
                                                    <div className="table_icons_open"
                                                         onClick={() => addLessonOpenModal(item)}><FaFolderOpen/></div>
                                                    <div className="table_icons_edit"
                                                         onClick={() => editcertificate(item)}><MdOutlineModeEdit/>
                                                    </div>
                                                    <div className="table_icons_delete"
                                                         onClick={() => deletecertificateModal(item)}><MdDeleteOutline/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all_courses_body_card_body">
                                                <p>{item?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            }

            {/*add certificate*/}
            <Modal
                isOpen={certificateOpenModal}
                onRequestClose={cencelcertificate}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="add_test_modal">
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={initialValuescertificate}
                        requiredMark='optional'
                        onFinish={onFinishcertificate}
                        onFinishFailed={onFinishFailedcertificate}
                        autoComplete="off"
                    >
                        <div className="add_test_modal_addModal_inner_text">
                            {initialValuescertificate?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Kurs yaratish</h2>}
                        </div>
                        <Form.Item
                            label={"Nomi"}
                            name="name"
                            rules={[{
                                required: true, message: 'Nomini kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Tavifi"}
                            name="description"
                            rules={[{
                                required: true, message: 'Tavifini kiriting',
                            },]}
                        >
                            {/*<Input*/}
                            {/*    size="large"*/}
                            {/*    placeholder={"Kiriting"}*/}
                            {/*/>*/}
                            <TextArea placeholder="Kiriting"/>
                        </Form.Item>
                        <Form.Item
                            label={"Narxi"}
                            name="price"
                            rules={[{
                                required: true, message: 'Narxini kiriting',
                            },]}

                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                                type="number"
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Mentorni tanlang"}
                            name="mentorId"
                            rules={[{
                                required: true, message: 'Mentorni tanlang',
                            },]}
                            placeholder={"Tanlang"}
                        >
                            <Select placeholder="Tanlang">
                                {
                                    mentor?.map((item ,index)=>{
                                        return(
                                            <Select.Option value={item?.id} key={index}>{item?.fullName}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <div className="add_test_modal_addModal_inner_buttons">
                            <button type='button' onClick={cencelcertificate}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                isOpen={certificateDeleteModal}
                // onRequestClose={cencelVideo}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Sertifikatni o'chirasizmi ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={cencelcertificate}>Yo'q</button>
                        <button onClick={deletecertificate}>Ha</button>
                    </div>
                </div>
            </Modal>
            {/*add certificate*/}

            {/*add img*/}
            <Modal
                isOpen={imgOpenModal}
                onRequestClose={cencelImg}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="landing_adds_body_inner_inner_addModal_inner">
                    <Form
                        name="basic"
                        layout="vertical"
                        // initialValues={initialValuesVideo}
                        requiredMark='optional'
                        onFinish={onFinishImg}
                        onFinishFailed={onFinishFailedImg}
                        autoComplete="off"
                    >
                        <div className="landing_adds_body_inner_inner_addModal_inner_text">
                            {initialValuesImg?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Rasm qo'shish</h2>}
                        </div>
                        <div className="user_list_modal_uploadImg">
                            <div className="user_list_modal_uploadImg_left">
                                <div className="user_list_modal_uploadImg_left_inner">
                                    {
                                        initialValuesImg.edit && !imageState.check ?
                                            <img
                                                src={`${ip}/api/photo/show/${initialValuesImg?.coverPhotoId}`}
                                                className="img1"/>
                                            :
                                            imageState.uploaded ?
                                                <img src={view} className="img1"/>
                                                : <img src={modalImg} className="img2"/>}
                                </div>
                            </div>
                            <div className="user_list_modal_uploadImg_right">
                                <div className="user_list_modal_uploadImg_right_inner">
                                    <label htmlFor='add_staff_img' className="upload_button">
                                        <div className="upload_button_icon">
                                            {/*<img src={UploadIcon} style={{marginRight: "8px"}}/>*/}
                                            <BsUpload size={22} style={{marginRight: "8px"}}/>
                                            {"Rasm yuklash"}
                                        </div>
                                        <input onChange={upload} name='image' type="file"
                                               id="add_staff_img"
                                               style={{display: 'none'}}
                                               className=""
                                        />
                                    </label>
                                    <div className="upload_button_text">
                                        <span>{"Maksimal fayl hajmi 1 Mb 500x500 o‘lchamda"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                            <button type='button' onClick={cencelImg}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            {/*add img*/}
        </div>
    );
};

export default AuthorshipCourse;


