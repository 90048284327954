import React from 'react';
import {Space, Table, Tag} from 'antd';


import fileIcon from '../../../../images/user/userDashboard/fileicon.png';
import './library.css';


import styled from 'styled-components';
import {ip} from "../../../../ip";
import {MdAdd, MdDeleteOutline, MdOutlineModeEdit} from "react-icons/md";

export const TableStyles = styled(Table)`
  tbody {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.trhover};
  }
`;

const LibraryTable = (props) => {

    const {
        data,
        setLibraryOpenModal,
        setInitialValuesLibrary,
        setLibraryOpenDeleteModal,
        setFileOpenModal
    } = props;

    const edit = (value, url) => {
        setInitialValuesLibrary({...value, edit: true});
        setLibraryOpenModal(true);
    }
    const deleteUrl = (value, record) => {
        setInitialValuesLibrary({...value});
        setLibraryOpenDeleteModal(true);
    }
    const uploadFile = (value , record) => {
        setInitialValuesLibrary({...value});
        setFileOpenModal(true);
    }
    const editFile = (value , record) => {

    }

    const columns = [
        {
            title: 'T/r',
            dataIndex: 'key',
            // align: 'center'
        },
        {
            title: "NOM",
            dataIndex: 'name',
            key: 'name',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.name}</div>,
        },
        {
            title: 'MUALLIF',
            dataIndex: 'MUALLIF',
            key: 'MUALLIF',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.author}</div>,
        },
        {
            title: 'YO’NALISH',
            dataIndex: 'address',
            key: 'address',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.subject}</div>,
        },
        {
            title: 'TURI',
            dataIndex: 'SUMMA',
            key: 'address',
            textAlign: 'center',
            render: (text, record) => <div className="library_body_table_text">{record.type}</div>,
        },
        {
            title: 'FAYL',
            dataIndex: '',
            // key: 'age',
            // textAlign: 'center',
            render: (text, record) =>
                (<div>
                    {record?.fileId ?
                        <div className={"library_body_table_text"}>
                            <a href={`${ip}/api/file/download/${record?.fileId}`}
                            style={{textDecoration: "none", color: "white"}} target="_blank"><img src={fileIcon}/></a>
                            <div className="table_icons_edit" style={{cursor : "pointer" , marginLeft : "15px"}}
                                 onClick={() => uploadFile(text, record)}>
                                <MdOutlineModeEdit style={{fontSize :"20px"}}/>
                            </div>
                        </div>
                        :
                        <div className="library_body_table_text library_body_table_text_no_pay"
                             onClick={() => uploadFile(text, record)}>
                            <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>FAYL BIRIKTIRISH</div>
                    }
                </div>)
        },
        {
            title: "AMALLAR",
            dataIndex: '',
            // align: 'center',
            render: (text, record) => (
                <div className="table_icons">
                    <div className="table_icons_edit" onClick={() => edit(text, record)}><MdOutlineModeEdit/></div>
                    <div className="table_icons_delete" onClick={() => deleteUrl(text, record)}><MdDeleteOutline/></div>
                </div>
            )
        }

    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default LibraryTable;