import React from 'react';
import { Space, Table, Tag } from 'antd';


import './cooperation.css';


import styled from 'styled-components';
export const TableStyles = styled(Table)`
  tbody {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({ theme }) => theme.trhover};
  }
`;

const CooperationTable = (props) => {

    const {
        data,
    } = props;


    const columns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
            // width: 50,
            align: 'center'
        },
        {
            title: 'F.I.SH',
            dataIndex: 'name',
            key: 'name',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'age',
            key: 'age',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'KURS',
            dataIndex: 'address',
            key: 'address',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'STATUS',
            dataIndex: 'age',
            key: 'age',
            textAlign : 'center',
            render: (text , record) => <div className={record.pay == true ? "cooperation_body_table_text cooperation_body_table_text_pay" :
                "cooperation_body_table_text cooperation_body_table_text_no_pay"}>{text}</div>
        },
        {
            title: 'BONUS',
            dataIndex: 'KURS',
            key: 'address',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },

    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default CooperationTable;