import React from 'react';

import './resultModal.css';
import Modal from "react-modal";
import Loader from "../../../../loading/Loader";
import {Form, Input} from "antd";
import {storage} from "../../../../../services";
import errorIcon from '../../../../../images/user/userDashboard/testResultModal/Group 14295.png';
import successIcon from '../../../../../images/user/userDashboard/testResultModal/Group 14296.png';
import {Link} from "react-router-dom";

const ResultModal = (props) => {
    const token = storage.local.get("token");
    const {
        open,
        setOpen,
        setOpenTestPage,
        result
    } = props;

    const cancel = () => {
        setOpen(false);
    }

    const exitTest = () => {
        setOpenTestPage(false);
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={cancel}
            contentLabel="My dialog"
            className="myModal"
            overlayClassName="myOverlay"
            closeTimeoutMS={300}
        >
            <div className="result_modal">
                {result?.item?.success ?
                    <div className="result_modal_inner">
                        <div className="result_modal_inner_icon">
                            <img src={successIcon}/>
                            <h1>TABRIKLAYMIZ !</h1>
                        </div>
                        <div className="result_modal_inner_text">
                            <p>Testni muvaffaqiyatli topshirdingiz! Quyidagi tugma orqali sertifikatingizni yuklab
                                olsangiz bo’ladi !</p>
                        </div>
                        <div className="result_modal_inner_button">
                            <button>YUKLAB OLISH</button>
                        </div>
                    </div>
                    :
                    <div className="result_modal_inner">
                        <div className="result_modal_inner_icon">
                            <img src={errorIcon}/>
                            <h1>AFSUS:(</h1>
                        </div>
                        <div className="result_modal_inner_text">
                            <p>
                                Testni muvaffaqiyatli topshira olmadingiz. Sertifikatga ega bo’lish uchun
                                bilimlaringizni
                                takrorlab, testni qayta topshiring !
                            </p>
                        </div>
                        <div className="result_modal_inner_button">
                            <button onClick={exitTest}>BOSH SAHIFAGA QAYTISH</button>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ResultModal;