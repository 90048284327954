import React, {useEffect, useState} from 'react';

import {DatePicker, Form, Input, message, Radio, Select} from "antd";
import axios from "axios";

import './loginPage.css';
import {ip} from "../../ip";
import {Link} from "react-router-dom";
import {LoginSuccess} from "../../redux/action/action";
import {useDispatch} from "react-redux";
import Loader from "../loading/Loader";

const Login = () => {

        const dispatch = useDispatch();

        const [loading, setLoading] = useState(false);

        const initialValues = {
            login: '',
            password: ''
        }

        const onFinish = (values) => {
            setLoading(true)
            // dispatch(LoginSuccess({name : "salom" , username : "eldor"}));
            axios.post(`${ip}/api/v1/auth/login`, {
                username: values.username,
                password: values.password
            })
                .then(res => {
                    // console.log(res);
                    message.success("Boshqaruv paneliga xush kelibsiz");
                    dispatch(LoginSuccess(res?.data?.item));
                    setLoading(false);
                    // localStorage.setItem('keys', JSON.stringify(['sub1']));
                    // localStorage.setItem('selected-id', JSON.stringify(['2']));
                })
                .catch(err => {
                    // console.log(err)
                    message.error("Xatolik !")
                    setLoading(false);
                });
        }
        const onFinishFailed = (e) => {
            // console.log(e)
        }


        return (
            <div className="login">
                {
                    loading && <Loader/>
                }
                <div className="login_inner">
                    <div className="login_inner_left">
                        <div className="login_inner_left_inner">
                            <h1><span>PRO</span> DIGITAL</h1>
                                <p>Boshqaruv paneli</p>
                        </div>
                    </div>
                    <div className="login_inner_right">
                        <div className="login_inner_right_inner">
                            <h1>Xush kelibsiz !</h1>
                            <p>Boshqaruv paneliga kirish uchun login va parolni kiriting</p>
                            <div className="login_inner_right_inner_form">
                                <Form
                                    name="basic"
                                    layout="vertical"
                                    requiredMark='optional'
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    initialValues={initialValues}
                                >
                                    <div className="login_inner_right_inner_form_field">
                                        <Form.Item name="username" rules={
                                            [{
                                                required: true,
                                                message: "Email pochtani kiriting"
                                            }]
                                        }>
                                            <Input placeholder="Email pochta"/>
                                        </Form.Item>
                                    </div>
                                    <div className="login_inner_right_inner_form_field">
                                        <Form.Item name="password" rules={
                                            [{
                                                required: true,
                                                message: "Parolni kiriting"
                                            }]
                                        }>
                                            <Input.Password placeholder="Parol"/>
                                        </Form.Item>
                                    </div>
                                    <div className="login_inner_right_inner_form_button">
                                        <button type="submit">KIRISH</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Login;