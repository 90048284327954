import React from 'react';
import {Table , Image} from 'antd';

import '../landingPageAdds.css';
import styled from 'styled-components';
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDeleteOutline, MdOutlineModeEdit, MdOutlineRemoveRedEye} from "react-icons/md";
import {ip} from "../../../../../ip";

export const TableStyles = styled(Table)`
  tbody {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.trhover};
  }
`;
const ImgTable = (props) => {
    const {
        imgData,
        setImgOpenModal,
        setInitialValuesImg,
        setImgOpenDeleteModal,
        rowSelection
    } = props;
    const edit = (value, url) => {
        setInitialValuesImg({...value, edit: true});
        setImgOpenModal(true);
    }
    const deleteImg = (value, record) => {
        setInitialValuesImg({...value});
        setImgOpenDeleteModal(true);
    }


    const columnsUz = [
        {
            title: 'T/r',
            dataIndex: 'key',
            // align: 'center'
        },
        {
            title: "Rasmlar",
            dataIndex: 'file',
            // align: 'center',
            render: (text, record) => (
                <div className="landing_adds_body_inner_inner_table_img">
                    <Image
                        width={60}
                        height={40}
                        src={`${ip}/api/photo/show/${record?.image}`}
                        preview={{
                            mask: (<MdOutlineRemoveRedEye style={{fontSize : "22px"}}/>),
                            maskClassName: 'customize-mask',
                        }}
                    />
                </div>
            )
        },
        {
            title: "Amallar",
            dataIndex: '',
            // align: 'center',
            render: (text, record) => (
                <div className="table_icons">
                    <div className="table_icons_edit" onClick={() => edit(text, record)}><MdOutlineModeEdit/></div>
                    <div className="table_icons_delete" onClick={()=>deleteImg(text, record)}><MdDeleteOutline/></div>
                </div>
            )
        }
    ]
    return (
        <TableStyles
            rowSelection={rowSelection}
            columns={columnsUz}
            dataSource={imgData}
            pagination={false}
        />
    );
};

export default ImgTable;